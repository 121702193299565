<template>
    <div>
        <div class="filter-container">
            <div class="left-content">
                <b-dropdown
                    class="filter-dropdown"
                    variant="default"
                    ref="drop"
                >
                    <template #button-content>
                        <span class="lc">
                            <i class="icon icon-filter"/> Фильтр
                        </span>
                        <span class="rc">
                            <i class="icon icon-keyboard"/>
                        </span>
                    </template>
                    <div>
                        <div class="top-content">
                            <span>Параметры фильтра</span>
                            <i
                                class="icon icon-close"
                                @click="closeFilterPanel"
                            />
                        </div>

                        <div class="filter-block">
                            <b-form-group label="Район">
                                <multiselect
                                    v-model="selectedRegion"
                                    :options="regions"
                                    placeholder="Выберите район"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    label="nameRu"
                                />
                            </b-form-group>
                        </div>

                        <div class="filter-block">
                            <b-form-group label="Сельский округ">
                                <multiselect
                                    v-model="selectedDistrict"
                                    :options="districts"
                                    placeholder="Выберите сельский округ"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    label="nameRu"
                                    :disabled="isFilterActive"
                                />
                            </b-form-group>
                        </div>

                        <div class="filter-block">
                            <b-form-group label="СНП">
                                <multiselect
                                    v-model="selectedSnp"
                                    :options="selectedDistrict ? snps : []"
                                    placeholder="Выберите СНП"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    label="nameRu"
                                    :disabled="isFilterActive"
                                />
                            </b-form-group>
                        </div>

                        <div class="filter-block">
                            <b-form-group label="Форма">
                                <multiselect
                                    v-model="selectedReportType"
                                    :options="[{ name: 'Все' }, ...reportTypes]"
                                    placeholder="Выберите форму"
                                    :allow-empty="false"
                                    :show-labels="false"
                                    label="name"
                                />
                            </b-form-group>
                        </div>

                        <div class="filter-block" style="margin-top: 30px;">
                            <b-button variant="primary" @click="applyFilter">Применить</b-button>
                            <b-button variant="secondary" @click="resetFilter">Сбросить</b-button>
                        </div>
                    </div>
                </b-dropdown>
            </div>

            <div class="filter-actions">
                <b-button
                    v-if="roles.VILLAGE"
                    variant="primary"
                    :disabled="!canSendToApprove()"
                    @click="handleAction('SEND_TO_APPROVE')"
                >
                    На согласование
                </b-button>
                <b-button
                    v-if="roles.DISTRICT || roles.DISTRICT_DEPARTMENTS"
                    variant="primary"
                    :disabled="!canReconcile()"
                    @click="handleReconcile"
                >
                    Согласовать
                </b-button>
                <b-button
                    v-if="roles.REGION || roles.REGION_DEPARTMENTS"
                    variant="success"
                    :disabled="!canApprove()"
                    @click="handleApprove"
                >
                    Утвердить
                </b-button>
                <b-button
                    v-if="roles.DISTRICT || roles.DISTRICT_DEPARTMENTS || roles.REGION || roles.REGION_DEPARTMENTS"
                    variant="secondary"
                    :disabled="!canSendToReprocess()"
                    @click="handleAction('SEND_TO_REPROCESS')"
                >
                    На доработку
                </b-button>
            </div>
        </div>

        <!---------Начало грид таблицы-------------->
        <div class="table-container" v-if="!loading">
            <div class="b-table-sticky-header table-responsive-true">
                <table
                    class="table b-table table-bordered b-table-no-border-collapse"
                >
                    <thead>
                        <tr class="text-center">
                            <th
                                colspan="1"
                                class="th-1"
                            >
                                <b-form-checkbox v-model="checkAll" @change="handleCheckboxAllChange"/>
                            </th>
                            <th
                                colspan="1"
                                class="th-1"
                                @click="this.toggleCollapseAll"
                            >
                                <img v-if="collapseAll" src="./icons/open.svg" alt="">
                                <img v-else src="./icons/closed.svg" alt="">
                            </th>
                            <th
                                colspan="4"
                                class="th-2"
                            >
                                {{ this.tableHeaders[0].ru }}
                            </th>
                            <th
                                colspan="4"
                                class="th-2"
                            >
                                {{ this.tableHeaders[1].ru }}
                            </th>
                            <th
                                colspan="4"
                                class="th-2"
                            >
                                {{ this.tableHeaders[2].ru }}
                            </th>
                            <th
                                colspan="4"
                                class="th-2"
                            >
                                {{ this.tableHeaders[3].ru }}
                            </th>
                            <th
                                colspan="7"
                                class="th-3"
                            >
                                {{ this.tableHeaders[4].ru }}
                            </th>
                            <th
                                colspan="7"
                                class="th-3"
                            >
                                {{ this.tableHeaders[5].ru }}
                            </th>
                            <th colspan="1" class="th-1"/>
                        </tr>
                    </thead>

                    <tbody v-if="tableShowAll && tableVisible && selectedRegion && selectedRegion.code[2] === '0' && selectedRegion.code[3] === '0'">
                        <template v-for="region in regions">
                            <tr style="background-color: #B0E0FF80;" :key="region.code">
                                <td colspan="1" class="text-center"/>
                                <td colspan="1" class="text-center">
                                    <button
                                        type="button"
                                        class="btn btn-secondary"
                                        @click="toggleSingleRegionOpen(region.code)"
                                    >
                                        <img v-if="regionsOpen.includes(region.code)" src="./icons/open.svg" alt="">
                                        <img v-else src="./icons/closed.svg" alt="">
                                    </button>
                                </td>
                                <td colspan="12">{{ region.nameRu }}</td>
                                <td colspan="4"/>
                                <td colspan="7" style="background-color: #FFF5DB;"/>
                                <td colspan="7">
                                    <div v-if="currentReportType && currentReportType.name === 'Все' && selectedRegion && averages.hasOwnProperty(region.code)">
                                        Среднее&nbsp;-&nbsp;{{ averages[region.code].toFixed(1) }}%
                                    </div>
                                </td>
                                <td colspan="1" class="th-1"/>
                            </tr>

                            <template v-if="selectedRegion && progresses[region.code] && regionsOpen.includes(region.code)">
                                <template v-for="progressItem of progresses[region.code]">
                                    <tr :key="`${region.code}-${progressItem.name}-${progressItem.report_id}`">
                                        <td colspan="1"/>
                                        <td colspan="13"/>
                                        <td colspan="4">{{ progressItem.name }}</td>
                                        <td colspan="7" style="background-color: #FFF5DB;"/>
                                        <td colspan="7">
                                            <div>{{ progressItem.progress.toFixed(1) }}%</div>
                                            <b-progress
                                                v-if="progressItem.progress"
                                                height="4px"
                                                :value="progressItem.progress"
                                                :variant="barVariants.find(bar => progressItem.progress >= bar.value).variant"
                                            />
                                        </td>
                                        <td colspan="1" class="th-1"/>
                                    </tr>
                                </template>
                            </template>

                            <template v-for="row of globalArr.filter(item => item.regionKatoCode === region.code)">
                            <tr
                                v-if="row.visible"
                                :key="`${region.code} - ${row.id} - ${row.reportId} - ${row.type}`"
                                :style="{
                                    backgroundColor: (row.type === 1 && '#B0E0FF4D') || (row.type === 2 && '#B0E0FF26'),
                                    background: row.type === 3 && 'linear-gradient(0deg, #FFF, #FFF), rgba(176, 224, 255, .15)'
                                }"
                            >
                                <td colspan="1" class="text-center">
                                    <b-form-checkbox
                                        v-if="row.type === 3"
                                        v-model="row.checked"
                                        :disabled="!checkRoleForCheckbox(row) || row.progress < 100 || checkItemsToActionStatuses(row)"
                                        @change="handleCheckboxChange(row)"
                                    />
                                </td>

                                <template v-if="row.type === 1">
                                    <td v-if="row.visible" colspan="5" class="text-right">
                                        <button
                                            type="button"
                                            class="btn btn-secondary"
                                            @click="toggleDistrictOpen(row)"
                                        >
                                            <img v-if="row.open" src="./icons/open.svg" alt="">
                                            <img v-else src="./icons/closed.svg" alt="">
                                        </button>
                                    </td>
                                    <td colspan="8">{{ row.districtNameRu }}</td>
                                </template>

                                <template v-else-if="row.type === 2">
                                    <td v-if="row.visible" colspan="9" class="text-right">
                                        <button
                                            type="button"
                                            class="btn btn-secondary"
                                            @click="toggleSnpOpen(row)"
                                        >
                                            <img v-if="row.open" src="./icons/open.svg" alt="">
                                            <img v-else src="./icons/closed.svg" alt="">
                                        </button>
                                    </td>
                                    <td colspan="4" @click="openVillagePassportModal(row.katoCode)">
                                      <span class="snpName">{{ row.snpNameRu || row.name }}</span>
                                    </td>
                                </template>

                                <template v-else>
                                    <td v-if="row.visible" colspan="13"/>
                                </template>

                                <template v-if="row.visible">
                                    <td colspan="4">
                                        <span
                                            v-if="row.type === 3 && reportTypes.length"
                                            class="snpName"
                                            @click="() => goToPassport(row)"
                                        >
                                            {{ reportTypes[row.reportId - 1].name }}
                                        </span>
                                    </td>
                                    <td colspan="7" style="background-color: #FFF5DB;">
                                        <div v-if="row.type === 3">
                                            <div
                                              v-if="row.status === 'IN_PROCESS'"
                                              class="authorHistory__status"
                                              :class="row.status"
                                            >
                                              {{ $t(`modules.monitoringSnp.statuses.${row.status}`) }}
                                            </div>
                                            <b-dropdown
                                              v-else
                                              class="authorHistory--reconciliation"
                                              variant="link"
                                              toggle-class="text-decoration-none"
                                              no-caret
                                            >
                                              <template #button-content>
                                                <span
                                                  class="authorHistory__status"
                                                  :class="row.status"
                                                  @click="() => loadHistory(row)"
                                                >
                                                  {{ $t(`modules.monitoringSnp.statuses.${row.status}`) }}
                                                </span>
                                              </template>
                                              <div>
                                                <b-spinner v-if="historyLoading" small label="Small Spinner"></b-spinner>
                                                <template v-else>
                                                  <div class="authorHistory__list" v-if="historyData.length > 0">
                                                    <div v-for="item of historyData" class="row authorHistory__item" :key="item.id">
                                                      <div class="col-4 authorHistory__status" :class="item.toStatus">
                                                        {{ $t(`modules.monitoringSnp.shortStatuses.${item.toStatus}`) }}
                                                      </div>
                                                      <div class="col-4">{{item.createdBy}}</div>
                                                      <div class="col-4">{{formatDate(item.createdAt)}}</div>
                                                    </div>
                                                  </div>
                                                  <div v-else class="authorHistory__notFound">
                                                    История не найдена
                                                  </div>
                                                </template>
                                              </div>
                                            </b-dropdown>
                                            <span
                                              v-if="row.commentFeedId"
                                              class="icon-box"
                                              style="cursor: pointer; margin-left: 10px;"
                                              @click="handleOpenCommentModal(row)"
                                            >
                                                <img src="./icons/comment.svg" alt="">
                                            </span>
                                        </div>
                                    </td>
                                    <td colspan="7">
                                        <template v-if="row.type === 3">
                                            <div>{{ row.progress.toFixed(1) }}%</div>
                                            <b-progress
                                                v-if="row.progress"
                                                height="4px"
                                                :value="row.progress"
                                                :variant="barVariants.find(bar => row.progress >= bar.value).variant"
                                            />
                                        </template>
                                        <template v-if="row.type === 2">
                                            <div v-if="currentReportType && currentReportType.name === 'Все'">
                                                Среднее - {{ row.districtAverageProgress.toFixed(1) }}%
                                            </div>
                                        </template>
                                        <template v-else-if="row.type === 1">
                                            <div v-if="currentReportType && currentReportType.name === 'Все' && averages.hasOwnProperty(row.districtKatoCode)">
                                                Среднее&nbsp;-&nbsp;{{ averages[row.districtKatoCode].toFixed(1) }}%
                                            </div>
                                            <b-progress
                                                v-if="row.avg"
                                                height="4px"
                                                :value="row.avg"
                                                :variant="barVariants.find(bar => row.avg >= bar.value).variant"
                                            />
                                        </template>
                                    </td>
                                    <td colspan="1" class="th-1">
                                        <span
                                            v-if="row.type === 3 && !roles.VILLAGE"
                                            :id="`vertical-dots-${row.id}-${row.type}`"
                                            style="height: 20px; padding: 2px; cursor: pointer;"
                                        >
                                            <img
                                                src="./icons/3-vertical-dots.svg"
                                                alt=""
                                                style="height: 100%; width: 4px;"
                                            />
                                        </span>
                                        <b-tooltip
                                            variant="light"
                                            :target="`vertical-dots-${row.id}-${row.type}`"
                                            triggers="hover"
                                        >
                                            <b-button
                                                variant="light"
                                                @click="handleOpenCommentModal(row)"
                                                style="font-size: 14px;"
                                            >
                                                Добавить/Открыть комментарий
                                            </b-button>
                                        </b-tooltip>
                                    </td>
                                </template>
                            </tr>

                            <template v-if="row.type === 1 && row.open && progresses[row.districtKatoCode]">
                                <template v-for="progressItem of progresses[row.districtKatoCode]">
                                    <tr :key="`${region.code}-${row.id}-${progressItem.name}-${progressItem.report_id}`">
                                        <td colspan="1"/>
                                        <td colspan="13"/>
                                        <td colspan="4">{{ progressItem.name }}</td>
                                        <td colspan="7" style="background-color: #FFF5DB;"/>
                                        <td colspan="7">
                                            <div>{{ progressItem.progress.toFixed(1) }}%</div>
                                            <b-progress
                                                v-if="progressItem.progress"
                                                height="4px"
                                                :value="progressItem.progress"
                                                :variant="barVariants.find(bar => progressItem.progress >= bar.value).variant"
                                            />
                                        </td>
                                            <td colspan="1" class="th-1"/>
                                        </tr>
                                    </template>
                                </template>
                            </template>
                        </template>
                    </tbody>

                    <tbody v-else-if="!tableShowAll && tableVisible && tableRegion && tableRegion.code[2] !== '0' && tableRegion.code[3] !== '0'">
                        <tr style="background-color: #B0E0FF80;">
                            <td colspan="1" class="text-center"/>
                            <td colspan="1" class="text-center">
                                <button
                                    type="button"
                                    class="btn btn-secondary"
                                    @click="toggleRegionOpen"
                                >
                                    <img v-if="regionOpen" src="./icons/open.svg" alt="">
                                    <img v-else src="./icons/closed.svg" alt="">
                                </button>
                            </td>
                            <td colspan="12">{{ tableRegion ? tableRegion.nameRu : '' }}</td>
                            <td colspan="4"/>
                            <td colspan="7" style="background-color: #FFF5DB;"/>
                            <td colspan="7">
                                <div v-if="currentReportType && currentReportType.name === 'Все' && tableRegion && averages.hasOwnProperty(tableRegion.code)">
                                    Среднее&nbsp;-&nbsp;{{ averages[tableRegion.code].toFixed(1) }}%
                                </div>
                            </td>
                            <td colspan="1" class="th-1"/>
                        </tr>


                        <template v-if="tableRegion && progresses[tableRegion.code] && regionOpen">
                            <template v-for="progressItem of progresses[tableRegion.code]">
                                <tr :key="`progress-${progressItem.name}-${progressItem.report_id}`">
                                    <td colspan="1"/>
                                    <td colspan="13"/>
                                    <td colspan="4">{{ progressItem.name }}</td>
                                    <td colspan="7" style="background-color: #FFF5DB;"/>
                                    <td colspan="7">
                                        <div>{{ progressItem.progress.toFixed(1) }}%</div>
                                        <b-progress
                                            v-if="progressItem.progress"
                                            height="4px"
                                            :value="progressItem.progress"
                                            :variant="barVariants.find(bar => progressItem.progress >= bar.value).variant"
                                        />
                                    </td>
                                    <td colspan="1" class="th-1"/>
                                </tr>
                            </template>
                        </template>


                        <template v-for="row of globalArr">
                            <tr
                                v-if="row.visible"
                                :key="`${row.id} - ${row.reportId} - ${row.type}`"
                                :style="{
                                    backgroundColor: (row.type === 1 && '#B0E0FF4D') || (row.type === 2 && '#B0E0FF26'),
                                    background: row.type === 3 && 'linear-gradient(0deg, #FFF, #FFF), rgba(176, 224, 255, .15)'
                                }"
                            >
                                <td colspan="1" class="text-center">
                                    <b-form-checkbox
                                        v-if="row.type === 3"
                                        v-model="row.checked"
                                        :disabled="!checkRoleForCheckbox(row) || row.progress < 100 || checkItemsToActionStatuses(row)"
                                        @change="handleCheckboxChange(row)"
                                    />
                                </td>

                                <template v-if="row.type === 1">
                                    <td v-if="row.visible" colspan="5" class="text-right">
                                        <button
                                            type="button"
                                            class="btn btn-secondary"
                                            @click="toggleDistrictOpen(row)"
                                        >
                                            <img v-if="row.open" src="./icons/open.svg" alt="">
                                            <img v-else src="./icons/closed.svg" alt="">
                                        </button>
                                    </td>
                                    <td colspan="8">{{ row.districtNameRu }}</td>
                                </template>

                                <template v-else-if="row.type === 2">
                                    <td v-if="row.visible" colspan="9" class="text-right">
                                        <button
                                            type="button"
                                            class="btn btn-secondary"
                                            @click="toggleSnpOpen(row)"
                                        >
                                            <img v-if="row.open" src="./icons/open.svg" alt="">
                                            <img v-else src="./icons/closed.svg" alt="">
                                        </button>
                                    </td>
                                    <td colspan="4" @click="openVillagePassportModal(row.katoCode)">
                                      <span class="snpName">{{ row.snpNameRu || row.name }}</span>
                                    </td>
                                </template>

                                <template v-else>
                                    <td v-if="row.visible" colspan="13"/>
                                </template>

                                <template v-if="row.visible">
                                    <td colspan="4">
                                        <span
                                            v-if="row.type === 3 && reportTypes.length"
                                            class="snpName"
                                            @click="() => goToPassport(row)"
                                        >
                                            {{ reportTypes[row.reportId - 1].name }}
                                        </span>
                                    </td>
                                    <td colspan="7" style="background-color: #FFF5DB;">
                                        <div v-if="row.type === 3">
                                            <div
                                              v-if="row.status === 'IN_PROCESS'"
                                              class="authorHistory__status"
                                              :class="row.status"
                                            >
                                              {{ $t(`modules.monitoringSnp.statuses.${row.status}`) }}
                                            </div>
                                            <b-dropdown
                                              v-else
                                              class="authorHistory--reconciliation"
                                              variant="link"
                                              toggle-class="text-decoration-none"
                                              no-caret
                                            >
                                              <template #button-content>
                                                <span
                                                  class="authorHistory__status"
                                                  :class="row.status"
                                                  @click="() => loadHistory(row)"
                                                >
                                                  {{ $t(`modules.monitoringSnp.statuses.${row.status}`) }}
                                                </span>
                                              </template>
                                              <div>
                                                <b-spinner v-if="historyLoading" small label="Small Spinner"></b-spinner>
                                                <template v-else>
                                                  <div class="authorHistory__list" v-if="historyData.length > 0">
                                                    <div v-for="item of historyData" class="row authorHistory__item" :key="item.id">
                                                      <div class="col-4 authorHistory__status" :class="item.toStatus">
                                                        {{ $t(`modules.monitoringSnp.shortStatuses.${item.toStatus}`) }}
                                                      </div>
                                                      <div class="col-4">{{item.createdBy}}</div>
                                                      <div class="col-4">{{formatDate(item.createdAt)}}</div>
                                                    </div>
                                                  </div>
                                                  <div v-else class="authorHistory__notFound">
                                                    История не найдена
                                                  </div>
                                                </template>
                                              </div>
                                            </b-dropdown>
                                            <span
                                              v-if="row.commentFeedId"
                                              class="icon-box"
                                              style="cursor: pointer; margin-left: 10px;"
                                              @click="handleOpenCommentModal(row)"
                                            >
                                                <img src="./icons/comment.svg" alt="">
                                            </span>
                                        </div>
                                    </td>
                                    <td colspan="7">
                                        <template v-if="row.type === 3">
                                            <div>{{ row.progress.toFixed(1) }}%</div>
                                            <b-progress
                                                v-if="row.progress"
                                                height="4px"
                                                :value="row.progress"
                                                :variant="barVariants.find(bar => row.progress >= bar.value).variant"
                                            />
                                        </template>
                                        <template v-if="row.type === 2">
                                            <div v-if="currentReportType && currentReportType.name === 'Все'">
                                                Среднее - {{ row.districtAverageProgress.toFixed(1) }}%
                                            </div>
                                        </template>
                                        <template v-else-if="row.type === 1">
                                            <div v-if="currentReportType && currentReportType.name === 'Все' && averages.hasOwnProperty(row.districtKatoCode)">
                                                Среднее&nbsp;-&nbsp;{{ averages[row.districtKatoCode].toFixed(1) }}%
                                            </div>
                                            <b-progress
                                                v-if="row.avg"
                                                height="4px"
                                                :value="row.avg"
                                                :variant="barVariants.find(bar => row.avg >= bar.value).variant"
                                            />
                                        </template>
                                    </td>
                                    <td colspan="1" class="th-1">
                                        <span
                                            v-if="row.type === 3 && !roles.VILLAGE"
                                            :id="`vertical-dots-${row.id}-${row.type}`"
                                            style="height: 20px; padding: 2px; cursor: pointer;"
                                        >
                                            <img
                                                src="./icons/3-vertical-dots.svg"
                                                alt=""
                                                style="height: 100%; width: 4px;"
                                            />
                                        </span>
                                        <b-tooltip
                                            variant="light"
                                            :target="`vertical-dots-${row.id}-${row.type}`"
                                            triggers="hover"
                                        >
                                            <b-button
                                                variant="light"
                                                @click="handleOpenCommentModal(row)"
                                                style="font-size: 14px;"
                                            >
                                                Добавить/Открыть комментарий
                                            </b-button>
                                        </b-tooltip>
                                    </td>
                                </template>
                            </tr>

                            <template v-if="row.type === 1 && row.open && progresses[row.districtKatoCode]">
                                <template v-for="progressItem of progresses[row.districtKatoCode]">
                                    <tr :key="`progress-${row.id}-${progressItem.name}-${progressItem.report_id}`">
                                        <td colspan="1"/>
                                        <td colspan="13"/>
                                        <td colspan="4">{{ progressItem.name }}</td>
                                        <td colspan="7" style="background-color: #FFF5DB;"/>
                                        <td colspan="7">
                                            <div>{{ progressItem.progress.toFixed(1) }}%</div>
                                            <b-progress
                                                v-if="progressItem.progress"
                                                height="4px"
                                                :value="progressItem.progress"
                                                :variant="barVariants.find(bar => progressItem.progress >= bar.value).variant"
                                            />
                                        </td>
                                        <td colspan="1" class="th-1"/>
                                    </tr>
                                </template>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-else class="spinner">
            <b-spinner variant="info" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
        </div>
        <b-modal v-model="commentModalOpen" @hide="handleCloseCommentModal()" centered hide-header hide-footer>
            <div class="comment-modal-body">
                <div class="comment-modal-title">Добавить комментарий:</div>
                <template v-if="modalCommentItem && modalCommentItem.comments">
                    <template v-for="comm of modalCommentItem.comments">
                        <div :key="comm.id" class="comment-modal-comment">
                            <div class="comment-modal-author">
                                <span>{{ comm.createdAt.slice(0, 10) }}</span>
                                <span>{{ comm.author }}</span>
                            </div>
                            <div class="comment-modal-text">{{ comm.comment }}</div>
                        </div>
                    </template>
                </template>
                <b-form-textarea
                    name="comment"
                    id="comment"
                    placeholder="Добавить комментарий"
                    rows="5"
                    max-rows="5"
                    v-model="comment"
                />
            </div>
            <b-button variant="success" @click="handleSendCommentModal">Отправить</b-button>
            <b-button variant="light" @click="handleCloseCommentModal">Отменить</b-button>
        </b-modal>
        <c-village-passport-modal ref="villagePassportModal"></c-village-passport-modal>
    </div>
</template>

<script>
import { MonitoringSnpApi } from '@/modules/monitoring-snp/api/MonitoringSnpApi';
import {
  APPROVED,
  APPROVED_BY_DEPARTMENTS,
  IN_PROCESS,
  RECONCILED,
  RECONCILED_BY_DEPARTMENTS,
  SEND_TO_APPROVE,
  SEND_TO_REPROCESS,
  ROLE_DISTRICT,
  ROLE_DISTRICT_DEPARTMENTS,
  ROLE_REGION,
  ROLE_REGION_DEPARTMENTS,
  ROLE_VILLAGE
} from "@/modules/monitoring-snp/consts/statuses";
import CVillagePassportModal from "@/modules/monitoring-snp/components/village-passport-modal";
import moment from "moment";

export default {
    name: 'Reconciliation',
    props: ['reportTypes', 'regions', 'me'],
    components: {
      'c-village-passport-modal': CVillagePassportModal,
    },
    data() {
        return {
            checkAll: false,
            collapseAll: false,
            allowAgreement: true,
            selectedRegion: null,
            districts: [],
            selectedDistrict: null,
            snps: [],
            selectedSnp: null,
            selectedReportType: {name: 'Все'},
            loading: false,
            tableHeaders: [
                {
                    ru: 'Район, город'
                },
                {
                    ru: 'Сельский округ, поселок'
                },
                {
                    ru: 'Сельский населенный пункт'
                },
                {
                    ru: 'Форма'
                },
                {
                    ru: 'Статус'
                },
                {
                    ru: 'Прогресс заполнения'
                }
            ],
            globalArr: [],
            roles: {
                VILLAGE: this.checkRole(ROLE_VILLAGE),
                DISTRICT_DEPARTMENTS: this.checkRole(ROLE_DISTRICT_DEPARTMENTS),
                DISTRICT: this.checkRole(ROLE_DISTRICT),
                REGION_DEPARTMENTS: this.checkRole(ROLE_REGION_DEPARTMENTS),
                REGION: this.checkRole(ROLE_REGION),
            },
            allowedActions: {
                IN_PROCESS: [SEND_TO_APPROVE],
                SEND_TO_APPROVE: [SEND_TO_REPROCESS, RECONCILED_BY_DEPARTMENTS],
                SEND_TO_REPROCESS: [SEND_TO_APPROVE],
                RECONCILED_BY_DEPARTMENTS: [RECONCILED, SEND_TO_REPROCESS],
                RECONCILED: [APPROVED_BY_DEPARTMENTS, SEND_TO_REPROCESS],
                APPROVED_BY_DEPARTMENTS: [APPROVED, SEND_TO_REPROCESS],
                APPROVED: []
            },
            statusColors: {
                SEND_TO_APPROVE: '#F79647',
                RECONCILED: '#2196F3',
                APPROVED: '#01AC50',
                SEND_TO_REPROCESS: '#C0504C',
                IN_PROCESS: '#6087A0'
            },
            barVariants: [
                {
                    value: 90,
                    variant: 'success'
                },
                {
                    value: 50,
                    variant: 'primary'
                },
                {
                    value: 30,
                    variant: 'warning'
                },
                {
                    value: 15,
                    variant: 'danger'
                },
                {
                    value: 0,
                    variant: 'secondary'
                }
            ],
            snpOpen: false,
            districtOpen: false,
            regionOpen: false,
            regionsOpen: [],
            tableVisible: true,
            districtMap: [],
            snpMap: {},
            infoArr: [],
            rowOpen: {},
            comment: '',
            commentModalOpen: false,
            selectedCommentItem: null,
            modalCommentItem: null,
            itemsToAction: [],
            progresses: {},
            averages: {},
            currentReportType: null,
            historyLoading: false,
            historyData: [],
            tableShowAll: false,
            isFilterActive: true,
            tableRegion: null,
        };
    },
    mounted() {
        this.me.katoCodes.length === 1 ? this.getInitialSnpInfo() : this.getDefaultInfo();
    },
    watch: {
        selectedRegion: function () {
            this.selectedDistrict = this.selectedSnp = null;
            this.selectedRegion?.code && this.loadDistricts();
            this.isFilterActive = this.selectedRegion?.code[2] === '0' && this.selectedRegion?.code[3] === '0';
        },
        selectedDistrict: function () {
            this.selectedSnp = null;
            if (this.selectedDistrict?.nameRu === 'Все') {
                this.snps = [];
            }
            this.selectedRegion?.code && this.selectedDistrict?.code && this.loadSnps();
        },
    },
    methods: {
        getDefaultInfo() {
            this.selectedRegion = this.regions.find(reg => reg.code === this.me.initialSnp.regionKatoCode);
            this.tableRegion = this.regions.find(reg => reg.code === this.me.initialSnp.regionKatoCode);
            this.reloadData();
        },
        getInitialSnpInfo() {
            MonitoringSnpApi
                .get('/snp/api/v1/reconciliations/',
                    { katoCode: this.me.katoCodes[0] }
                )
                .then(res => {
                    const data = {
                        ...res.data[0],
                        code: res.data[0].katoCode,
                        nameRu: res.data[0].snpNameRu
                    };
                    this.selectedRegion = this.regions.find(reg => reg.code === this.me.initialSnp.regionKatoCode);
                    this.selectedSnp = data;

                    MonitoringSnpApi
                        .get('/snp/api/v1/admin/dict-kato', { parentId: this.selectedRegion.id })
                        .then(res => {
                            this.districts = res.data;
                            this.selectedDistrict = this.districts.find(dist => dist.code === this.me.initialSnp.districtKatoCode);
                            MonitoringSnpApi
                                .get('/snp/api/v1/admin/dict-kato', { parentId: this.selectedDistrict.id })
                                .then(res => {
                                    this.snps = res.data;

                                    this.selectedSnp = this.snps.find(snp => snp.code === +this.me.katoCodes[0]);
                                    this.selectedReportType = { name: 'Все' };
                                    this.reloadData();
                                })
                                .catch(() => {
                                });
                        })
                        .catch(() => {
                        });
                })
                .catch(() => {
                });
        },
        checkRole(role) {
            if (this.me?.roles) {
                return this.me.roles.find(r => r.includes(role));
            }
            return false;
        },
        checkRoleForCheckbox(row) {
            let roles = [];
            if (this.me?.roles) {
                if (this.me.roles.some(r => r.startsWith(ROLE_VILLAGE))) {
                    roles = [...roles, SEND_TO_APPROVE];
                }
                if (this.me.roles.some(r => r.includes(ROLE_DISTRICT_DEPARTMENTS))) {
                    roles = roles.includes(SEND_TO_REPROCESS) ? [...roles, RECONCILED_BY_DEPARTMENTS] : [...roles, SEND_TO_REPROCESS, RECONCILED_BY_DEPARTMENTS];
                }
                if (this.me.roles.some(r => r.startsWith(ROLE_DISTRICT))) {
                    roles = roles.includes(SEND_TO_REPROCESS) ? [...roles, RECONCILED] : [...roles, SEND_TO_REPROCESS, RECONCILED];
                }
                if (this.me.roles.some(r => r.includes(ROLE_REGION_DEPARTMENTS))) {
                    roles = roles.includes(SEND_TO_REPROCESS) ? [...roles, APPROVED_BY_DEPARTMENTS] : [...roles, SEND_TO_REPROCESS, APPROVED_BY_DEPARTMENTS];
                }
                if (this.me.roles.some(r => r.startsWith(ROLE_REGION))) {
                    roles = roles.includes(SEND_TO_REPROCESS) ? [...roles, APPROVED] : [...roles, SEND_TO_REPROCESS, APPROVED];
                }
            }

            return roles.some(role => role !== SEND_TO_REPROCESS && this.allowedActions[row.status].includes(role)) &&
                this.me.katoCodes.includes(row.katoCode.toString()) && (row.status !== IN_PROCESS || row.progress === 100);
        },
        checkItemsToActionStatuses(row){
            if (this.itemsToAction.length === 0) {
                return false
            }

            if (row.status === SEND_TO_REPROCESS || row.status === IN_PROCESS){
                if (this.itemsToAction.some(r => r.status === SEND_TO_REPROCESS) || this.itemsToAction.some(r => r.status === IN_PROCESS)){
                    return false;
                }
            }

            if (this.itemsToAction.every(r => r.status === row.status)){
                return false
            }

            return true;
        },
        loadDistricts() {
            console.log('loadDistricts')
            MonitoringSnpApi
                .get('/snp/api/v1/admin/dict-kato', { parentId: this.selectedRegion.id })
                .then(res => this.districts = [{ nameRu: 'Все' }, ...res.data])
                .catch(() => {
                });
        },
        loadSnps() {
            console.log('loadSnps')
            MonitoringSnpApi
                .get('/snp/api/v1/admin/dict-kato', { parentId: this.selectedDistrict.id })
                .then(res => this.snps = [{ nameRu: 'Все' }, ...res.data])
                .catch(() => {
                });
        },
        loadProgress(parent) {
            const params = { katoCode: parent.code };
            if (this.selectedReportType?.id) {
                params.reportId = this.selectedReportType.id;
            }
            return MonitoringSnpApi
                .get('/snp/api/v1/reconciliations/progress-total/', params)
                .then(res => {
                    this.progresses[parent.code] = res.data.progress.filter(item => item.report_id !== 7).map(item => ({
                        ...item,
                        name: this.reportTypes[item.report_id - 1].name,
                        progress: item.progress_percentage,
                        parent: parent.nameRu,
                        open: false,
                        visible: false,
                        checked: false,
                        type: 3
                    }));
                    this.averages[res.data.code] = res.data.average_progress;
                    this.globalArr.reverse().reverse();
                })
                .catch(() => {
                });
        },
        loadData() {
            this.loadProgress({
                code: this.selectedRegion.code,
                nameRu: this.nameRu
            });

            if (this.selectedRegion.code[2] === '0' && this.selectedRegion.code[3] === '0'){
                this.regions.forEach(item => this.loadProgress(item));
                this.tableShowAll = true;
            } else {
                this.tableShowAll = false;
            }

            this.districtMap = {};
            this.snpMap = {};
            const filterRequest = {};
            if (this.selectedSnp?.code) {
                filterRequest.katoCode = this.selectedSnp.code;
            } else if (this.selectedDistrict?.code) {
                filterRequest.katoCode = this.selectedDistrict.code;
            } else if (this.selectedRegion?.code) {
                filterRequest.katoCode = this.selectedRegion.code;
            }
            if (this.selectedReportType?.id) {
                filterRequest.reportId = this.selectedReportType.id;
            }
            this.loading = true;
            filterRequest.katoCode && (
                MonitoringSnpApi
                    .get('/snp/api/v1/reconciliations', filterRequest)
                    .then(res => {
                        this.infoArr = res.data;
                        this.infoArr.forEach((item) => {
                            //console.log(item.reportId);
                            if (item.reportId !== 7) {
                                if (!this.districtMap.hasOwnProperty(item.districtNameRu)) {
                                    const obj = {
                                        id: item.id,
                                        code: item.districtKatoCode,
                                        nameRu: item.districtNameRu,
                                        open: true,
                                        checked: false,
                                        type: 2,
                                    };
                                    this.districtMap[item.districtNameRu] = obj;
                                    this.globalArr.push({
                                        ...item,
                                        open: false,
                                        visible: true,
                                        checked: false,
                                        type: 1
                                    });
                                    this.rowOpen[item.districtKatoCode] = false;
                                    this.loadProgress(obj);
                                    this.loadGlobalArr(obj);
                                }
                            }
                        });

                        if (this.selectedRegion) {
                            this.regionOpen = true;
                            this.collapseAll = true;
                            if (this.selectedDistrict) {
                                this.districts.forEach((district) => {
                                    district.open = true;
                                });
                                if (this.selectedSnp) {
                                    this.snps.forEach((snp) => {
                                        snp.open = true;
                                    });
                                }
                            }
                        }
                        this.currentReportType = this.selectedReportType;
                    })
                    .catch(() => this.makeToast(
                        'danger',
                        'По данному фильтру согласовании не существует',
                        'Ошибка')
                    )
                    .finally(() => this.loading = false)
            );
        },
        loadGlobalArr(parent) {
            this.infoArr.forEach(item => {
                if (parent.code === item.districtKatoCode) {
                    if (!this.snpMap.hasOwnProperty(item.katoCode)) {
                        this.snpMap[item.katoCode] = {
                            id: item.id,
                            code: item.katoCode,
                            nameRu: item.snpNameRu,
                            open: false,
                            checked: false,
                            parent: item.districtNameRu,
                            children: [],
                            avg: 0
                        };
                        item.reportId !== 7 && this.globalArr.push({
                            ...item,
                            open: false,
                            visible: false,
                            checked: false,
                            type: 2
                        });
                        this.rowOpen[item.katoCode] = false;
                    }
                    item.reportId !== 7 && this.globalArr.push({
                        ...item,
                        open: false,
                        visible: false,
                        checked: false,
                        type: 3
                    });
                    this.snpMap[item.katoCode].avg += item.progress;
                    this.snpMap[item.katoCode].children.push(item);
                }
            });
        },
        makeToast(variant = null, toastBody, title) {
            this.$bvToast.toast(toastBody, {
                title: title,
                variant: variant,
                autoHideDelay: 4000,
                solid: true
            });
        },
        toggleCollapseAll() {
            this.collapseAll = !this.collapseAll;
            this.regionOpen = this.collapseAll;
            this.globalArr.forEach(item => {
                if (this.collapseAll) {
                    item.visible = true;
                    item.open = true;
                } else {
                    item.visible = false;
                    item.open = false;
                }
            });
        },
        toggleRegionOpen() {
            this.regionOpen = !this.regionOpen;
            this.collapseAll = this.regionOpen;
            this.regionOpen ? (
                this.globalArr.forEach(item => {
                    if (item.type === 1) {
                        item.visible = true;
                    }
                })
            ) : (
                this.globalArr.forEach(item => {
                    item.open = false;
                    item.visible = false;
                })
            );
        },
        toggleSingleRegionOpen(code){
            console.log(code, "code");
            this.regionsOpen = this.regionsOpen.includes(code) ? this.regionsOpen.filter(item => item != code) : [...this.regionsOpen, code]
        },
        toggleDistrictOpen(row) {
            row.open = !row.open;
            row.open ? (
                this.globalArr.forEach((item) => {
                    if (row.districtKatoCode === item.districtKatoCode && item.type === 2) {
                        item.visible = row.open;
                    }
                })
            ) : (
                this.globalArr.forEach((item) => {
                    if (row.districtKatoCode === item.districtKatoCode && (item.type === 3 || item.type === 2)) {
                        if (item.type === 2) {
                            item.open = false;
                        }
                        item.visible = row.open;
                    }
                })
            );
        },
        toggleSnpOpen(row) {
            row.open = !row.open;
            this.globalArr.forEach((item) => {
                if (row.katoCode === item.katoCode && item.type === 3) {
                    item.visible = row.open;
                }
            });
        },
        closeFilterPanel() {
            this.$refs.drop.hide();
        },
        reloadData() {
            this.globalArr = [];
            this.loadData();
        },
        applyFilter() {
            if ((this.selectedSnp && this.selectedDistrict && this.selectedReportType) || (this.selectedRegion?.code[2] === '0' && this.selectedRegion?.code[3] === '0')) {
                this.tableRegion = this.selectedRegion;
                this.reloadData();
                this.closeFilterPanel();
            } else if (this.selectedDistrict && this.selectedDistrict.nameRu === 'Все'){
                this.tableRegion = this.selectedRegion;
                this.reloadData();
                this.closeFilterPanel();
            } else {
                this.makeToast(
                    'info',
                    'Все поля должны быть заполнены',
                    'Не удалось применить фильтры'
                )
            }
        },
        resetFilter() {
            this.selectedRegion = this.selectedDistrict = this.selectedSnp = this.selectedReportType = null;
            this.districts = this.snps = this.globalArr = [];
            this.itemsToAction = [];
            this.regionOpen = false;
            this.closeFilterPanel();
        },
        canSendToReprocess() {
            const { DISTRICT, DISTRICT_DEPARTMENTS, REGION, REGION_DEPARTMENTS } = this.roles;
            const statuses = this.itemsToAction.map(item => item.status);
            return statuses.length && (
                (DISTRICT_DEPARTMENTS && statuses.every(status => status === SEND_TO_APPROVE)) ||
                (DISTRICT && statuses.every(status => status === RECONCILED_BY_DEPARTMENTS)) ||
                (REGION_DEPARTMENTS && statuses.every(status => status === RECONCILED)) ||
                (REGION && statuses.every(status => status === APPROVED_BY_DEPARTMENTS))
            )
        },
        canReconcile() {
            const { DISTRICT, DISTRICT_DEPARTMENTS } = this.roles;
            const statuses = this.itemsToAction.map(item => item.status);
            return statuses.length && (
                (DISTRICT_DEPARTMENTS && statuses.every(status => status === SEND_TO_APPROVE)) ||
                (DISTRICT && statuses.every(status => status === RECONCILED_BY_DEPARTMENTS))
            )
        },
        handleReconcile() {
            const status = this.itemsToAction.every(r => r.status === RECONCILED_BY_DEPARTMENTS) ? RECONCILED : RECONCILED_BY_DEPARTMENTS;
            this.handleAction(status);
        },
        canSendToApprove(){
            return this.itemsToAction.length && this.itemsToAction.every(r => r.status === IN_PROCESS || r.status === SEND_TO_REPROCESS)
        },
        canApprove() {
            const { REGION, REGION_DEPARTMENTS } = this.roles;
            const statuses = this.itemsToAction.map(item => item.status);
            return statuses.length && (
                (REGION_DEPARTMENTS && statuses.every(status => status === RECONCILED)) ||
                (REGION && statuses.every(status => status === APPROVED_BY_DEPARTMENTS))
            )
        },
        handleApprove() {
            const status = this.itemsToAction.every(r => r.status === APPROVED_BY_DEPARTMENTS) ? APPROVED : APPROVED_BY_DEPARTMENTS;
            this.handleAction(status);
        },
        handleAction(action) {
            const data = this.itemsToAction.map(item => ({ reconciliationId: item.id, status: action }));
            MonitoringSnpApi
                .post('/snp/api/v1/reconciliations', data)
                .then(() => {
                    this.itemsToAction.forEach(item => {
                        const row = this.globalArr.find(row => row.type === 3 && row.id === item.id);
                        row.status = action;
                        row.checked = false;
                    });
                    this.checkAll = false;
                    this.itemsToAction = [];
                    this.makeToast('success', 'Успешно обновлен!', 'Запрос обработан');
                })
                .catch(() => this.makeToast('danger', 'Произошла ошибка на сервере', 'Ошибка'));
        },
        getComments() {
            MonitoringSnpApi
                .get(`/snp/api/v1/feed/${this.selectedCommentItem.commentFeedId}/`)
                .then(res => {
                    this.modalCommentItem = res.data;
                    this.commentModalOpen = true;
                })
                .catch(() => {
                });
        },
        handleOpenCommentModal(item) {
            this.selectedCommentItem = item;
            if (item.commentFeedId) {
                this.getComments();
            } else {
                this.commentModalOpen = true;
            }
        },
        createCommentFeed() {
            MonitoringSnpApi
                .post(`/snp/api/v1/feed/reconciliation/${this.selectedCommentItem.id}/`,
                    { comment: this.comment }
                )
                .then((res) => {
                    this.selectedCommentItem.commentFeedId = res.data.id;
                    this.modalCommentItem = res.data;
                    this.comment = '';
                    this.handleCloseCommentModal();
                })
                .catch(() => {
                });
        },
        handleSendCommentModal() {
            if (this.selectedCommentItem.commentFeedId) {
                MonitoringSnpApi
                    .put(`/snp/api/v1/feed/${this.selectedCommentItem.commentFeedId}`,
                        { comment: this.comment }
                    )
                    .then(res => {
                        this.modalCommentItem = res.data;
                        this.comment = '';
                        this.handleCloseCommentModal();
                    })
                    .catch(() => this.makeToast('danger', 'Ошибка', 'Ошибка запроса на сервере'));
            } else {
                this.createCommentFeed();
            }
        },
        handleCloseCommentModal() {
            this.commentModalOpen = false;
            this.selectedCommentItem = null;
            this.modalCommentItem = null;
        },
        handleCheckboxChange(item) {
            if (item.checked) {
                this.itemsToAction.push(item);
            } else {
                const index = this.itemsToAction.findIndex(i => i.id === item.id);
                this.itemsToAction.splice(index, 1);
            }
            if (!item.checked && this.checkAll) {
                this.checkAll = false;
            }
        },
        handleCheckboxAllChange() {
            this.globalArr.forEach(item => {
                if (item.type === 3 && item.visible && this.checkRoleForCheckbox(item)) {
                    item.checked = this.checkAll;
                }
            });
            if (this.checkAll) {
                this.itemsToAction = this.globalArr.filter(item => (
                    item.type === 3 && item.visible && this.checkRoleForCheckbox(item))
                );
            } else {
                this.itemsToAction = [];
            }
        },
        openVillagePassportModal(code) {
            this.$refs.villagePassportModal.showEvent(code);
        },
        goToPassport(row) {
          this.$emit('go-to-passport', {
            regionCode: row.regionKatoCode,
            districtCode: row.districtKatoCode,
            snpCode: row.katoCode,
            selectedSnp: {
              code: row.katoCode,
              nameRu: row.snpNameRu,
            },
            selectedReportType: {
              id: row.reportId,
              name: this.reportTypes[row.reportId - 1].name
            },
          })
        },
        loadHistory(row) {
          const params = {
            katoCode: this.currentSnp?.code || row.katoCode,
            year: new Date().getFullYear() - 1,
            reportId: row.reportId
          };

          this.historyLoading = true;
          MonitoringSnpApi
              .get(`/snp/api/v1/reconciliations/history`, params)
              .then(({ data }) => {
                this.historyData = data;
                this.historyLoading = false;
              })
              .catch(() => this.historyLoading = false);
        },
        formatDate(date) {
          return moment(date).format('DD.MM.YYYY HH:mm');
        },
    }
};
</script>
<style lang="scss" scoped>
img {
    cursor: pointer;
}

.spinner {
    padding-top: calc(100vh / 3.3);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eef1f8;
}

th, td {
    height: 50px;
}

.th-1 {
    width: 4%;
    text-align: center;
}

.th-2 {
    width: 12%;
    text-align: left;
}

.th-3 {
    width: 20%;
    text-align: left;
}

.comment-modal-body {
    .comment-modal-title {
        margin-bottom: 16px;
        font-size: 14px;
        font-weight: 700;
    }

    .comment-modal-author {
        color: #7C9DB3;
        display: flex;
        gap: 13px;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
    }

    .comment-modal-text {
        margin-top: 4px;
        border: 1px solid #BCD2E0;
        border-radius: 5px;
        padding: 5px 7px 9px 10px;
        color: #1C2A3E;

        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
    }

    textarea {
        min-width: 100%;
        background-color: #fff;
        border: 1px solid #BCD2E0;
        font-size: 12px;
        resize: none;
        border-radius: 5px !important;
        margin: 25px 0;
    }
}
.snpName {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
