




























































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import VueElementLoading from 'vue-element-loading';
import { MonitoringSnpApi } from '@/modules/monitoring-snp/api/MonitoringSnpApi';

interface IVillagePassportAddress {
  region: string;
  district: string;
  snp: string;
  code: string;
}

interface IVillagePassportValue {
  year: number;
  head: string;
  columnNumber: number;
  reportId: number;
  value: any;
}

interface IVillagePassportData {
  photo?: any;
  address?: IVillagePassportAddress;
  values?: IVillagePassportValue[]
}

@Component({
  name: 'c-village-passport-modal',
  components: { 'loading': VueElementLoading }
})
export default class CVillagePassportModal extends Vue {
  private years: number[] = [];
  private showModal = false;
  private code?: number;
  private filteredYear = 2018;
  private data: IVillagePassportData = {};
  private isLoading: boolean = false;

  private showEvent(code: number) {
    if (!code) { return; }
    this.code = code;
    this.loadYears();
    this.showModal = true;
  }

  private loadYears() {
    MonitoringSnpApi
      .get('/snp/api/v1/admin/years')
      .then(res => {
        this.years = res?.data?.length ? res.data.sort() : [new Date().getFullYear()];
        this.filteredYear = this.years[0];
        this.getData();
      })
      .catch(() => {
        this.years = [new Date().getFullYear()];
        this.filteredYear = this.years[0];
        this.getData();
      })
  }

  private getData() {
    this.isLoading = true;
    const params = { code: this.code, year: this.filteredYear }
    MonitoringSnpApi
      .get('/snp/api/v1/report/settlement/details', params)
      .then(({ data }: { data: IVillagePassportData }) => this.data = data)
      .catch(() => this.isLoading = false)
      .finally(() => this.isLoading = false);
  }

  private downloadFile() {
    const params = { code: this.code, year: this.filteredYear }
    MonitoringSnpApi.downloadFile('/snp/api/v1/report/file/settlement/details', params);
  }

  private handleUploadFile(event: any) {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);

    MonitoringSnpApi
      .post(`/snp/api/v1/report/photo/upload?code=${this.code}`, formData)
      .then(() => this.getData())
      .catch(() => {});
  }

}
