export const notClickContructor = [
    'из них', 'поголовье скота всего, гол',
    'цена за реализацию (тыс.тенге)', 'цена реализации, тыс. тенге за тонну',
    'в том числе', 'в том числе:', 'торговля', 'бытовое обслуживание', 'общественное питание',
    'удалённость, км', 'наличие ирригационных (орошаемых) систем',
    'в возрасте', 'миграция населения', 'за последние 10 лет до отчетного года',
    'занятость', 'координаты снп', 'количество зарег-ной с/х техники, ед.', 'объекты сферы обслуживания',
    'тип водоснабжения',
    'централизованное', 'резервуар чистой воды', 'водонапорная башня или напорный резервуар',
    'водоразборные колонки', 'децентрализованное', 'подземные', 'поверхностные (река, родник, озеро)',
    'связь', 'количество отделений почтовой связи', 'электроснабжение',
    'ктп, тп', 'газификация', 'обеспеченность газом', 'состояние дороги от снп до райцентра, км',
    //'протяженность автомобильной дороги, связывающие рц с  областным центром, км',
    'состояние дороги от рц до областного центра, км', 'в том числе состояние дороги',
    'в том числе по типу покрытия',
    'школьного возраста (только обучающие в школах и колледжах)',
    'детские сады', 'мини-центры', 'начальная', 'основная', 'средняя',
    'центральная районная больница', 'районная больница (специализированные)',
    'сельская больница (в т.ч. участковая)', 'наличие медицинских учреждений',
    'центральная районная больница', 'районная больница (специализированные)', 'сельская больница (в т.ч. участковая)',
    'поликлиники', 'врачебная амбулатория (семейная)', 'фельдшерско-акушерский пункт (в т.ч. фельдшерский пункт)',
    'медицинский пункт', 'численность медицинского персонала в соответствии со штатными нормативами, чел. (физических лиц)',
    'врачей', 'среднего мед. персонала', 'культура', 'культурно-досуговые предприятия (клубы и дома культуры)',
    'библиотеки', 'кинотеатры', 'музеи', 'спортивные сооружения  (включая дюсш) ед.', 'стадионы',
    'крытые спортивные комплексы', 'крытые спортивные комплексы', 'спортивные залы', 'плавательные бассейны',
    'лыжные базы', 'открытые спортивные площадки', 'туризм', 'в том числе ед.',
    'спорт', 'стадионы', 'в т.ч. воркаут и многофункциональные спорт. площадки',
    'в т.ч. минифутбольные поля', 'в т.ч. хоккейные корты', 'привозная'
];

export const notView = [
    'наименование района (города областного значения)',
    'наименование сельского округа или поселка',
    'наименование сельского населенного пункта',
    'статус села (опорное/ спутниковое)',
    'численность населения', 'численность населения ', ' численность населения',
    'примечание', 'примечания',
    //'наличие акимата с/о. села, поселка (есть)',
    'количество дворов',
    //'год постройки',
    //'год последнего капитального ремонта',
    'процент износа здания',
    'типовая, приспособленная',
    'состояние аварийности (да/нет)',
    'год постройки (открытия)',
    //'год последнего капитального ремонта',
    'процент износа здания',
    'типовая, приспособленная',
    'статус снп (опорный/спутниковый)',
    'всего'
];

export const notClickContructorNew = [
    {
        "1": [
            'из них', 'поголовье скота всего, гол', 'цена за реализацию (тыс.тенге)', 'цена реализации, тыс. тенге за тонну',
            'в том числе', 'в том числе:', 'количество зарег-ной с/х техники, ед.',  'торговля', 'бытовое обслуживание',
            'удалённость, км', 'объекты сферы обслуживания'
        ]
    },
    {
        "2": [
            'из них', 'в том числе', 'в том числе:', 'наличие ирригационных (орошаемых) систем'
        ]
    },
    {
        "3": [
            'из них', 'в том числе', 'в том числе:', 'в возрасте', 'миграция населения', 'за последние 10 лет до отчетного года',
            'занятость', 'координаты снп'
        ]
    },
    {
        "4": [
            'в том числе', 'в том числе:', 'тип водоснабжения',
            'централизованное', 'резервуар чистой воды', 'водонапорная башня или напорный резервуар',
            'водоразборные колонки', 'децентрализованное', 'подземные', 'поверхностные (река, родник, озеро)',
            'привозная'
        ]
    },
    {
        "5": [
            'в том числе', 'в том числе:', 'связь', 'количество отделений почтовой связи', 'электроснабжение',
            'ктп, тп', 'газификация', 'обеспеченность газом',
        ]
    },
    {
        "6": [
            'из них', 'состояние дороги от снп до райцентра, км',
            'состояние дороги от рц до областного центра, км', 'в том числе состояние дороги',
            'в том числе по типу покрытия'
        ]
    },
    {
        "7": [
            
        ]
    },
    {
        "8": [
            'в том числе', 'в том числе:', 'школьного возраста (только обучающие в школах и колледжах)',
            'детские сады', 'мини-центры', 'начальная', 'основная', 'средняя', 
        ]
    },
    {
        "9": [
            'центральная районная больница', 'районная больница (специализированные)', 'сельская больница (в т.ч. участковая)',
            'поликлиники', 'врачебная амбулатория (семейная)', 'фельдшерско-акушерский пункт (в т.ч. фельдшерский пункт)',
            'медицинский пункт', 'численность медицинского персонала в соответствии со штатными нормативами, чел. (физических лиц)',
            'врачей', 'среднего мед. персонала'
        ]
    },
    {
        "10": [
            'культура', 'культурно-досуговые предприятия (клубы и дома культуры)',
            'библиотеки', 'кинотеатры', 'музеи', 'спортивные сооружения  (включая дюсш) ед.', 'стадионы',
            'крытые спортивные комплексы', 'крытые спортивные комплексы', 'спортивные залы', 'плавательные бассейны',
            'лыжные базы', 'открытые спортивные площадки', 'туризм', 'в том числе ед.'
        ]
    },
    {
        "11": [
            'наименование района (города областного значения)',
            'наименование сельского округа или поселка',
            'наименование сельского населенного пункта',
            'статус села (опорное/ спутниковое)',
            'численность населения', 'примечание'
        ]
    }
];

export const notViewNew = [
    {
        "1": [
            'наименование района (города областного значения)',
            'наименование сельского округа или поселка',
            'наименование сельского населенного пункта',
            'статус села (опорное/ спутниковое)',
            'численность населения', 'примечание', 'всего'
        ]
    },
    {
        "2": [
            'наименование района (города областного значения)',
            'наименование сельского округа или поселка',
            'наименование сельского населенного пункта',
            'статус села (опорное/ спутниковое)',
            'численность населения', 'примечание'
        ]
    },
    {
        "3": [
            'наименование района (города областного значения)',
            'наименование сельского округа или поселка',
            'наименование сельского населенного пункта',
            'статус села (опорное/ спутниковое)',
            //'наличие акимата с/о. села, поселка (есть)',
            'численность населения', 'количество дворов', 'примечание'
        ]
    },
    {
        "4": [
            'наименование района (города областного значения)',
            'наименование сельского округа или поселка',
            'наименование сельского населенного пункта',
            'статус села (опорное/ спутниковое)',
            'численность населения',  'примечание', 'примечания'
        ]
    },
    {
        "5": [
            'наименование района (города областного значения)',
            'наименование сельского округа или поселка',
            'наименование сельского населенного пункта',
            'статус села (опорное/ спутниковое)',
            'численность населения', 'примечание'
        ]
    },
    {
        "6": [
            'наименование района (города областного значения)',
            'наименование сельского округа или поселка',
            'наименование сельского населенного пункта',
            'статус села (опорное/ спутниковое)',
            'численность населения', 'примечание'
        ]
    },
    {
        "7": [
        ]
    },
    {
        "8": [
            'наименование района (города областного значения)',
            'наименование сельского округа или поселка',
            'наименование сельского населенного пункта',
            'статус снп (опорный/спутниковый)', 'статус села (опорное/ спутниковое)',
            'численность населения',  'примечание', 'примечания',
            'год постройки', 'год последнего капитального ремонта',
            'процент износа здания', 'типовая, приспособленная',
            'состояние аварийности (да/нет)', 'год постройки (открытия)',
            'типовая, приспособленная', 'обучаются в 3-4 смены'
        ]
    },
    {
        "9": [
            'наименование района (города областного значения)',
            'наименование сельского округа или поселка',
            'наименование сельского населенного пункта',
            'статус села (опорное/ спутниковое)',
            'численность населения', 'примечание'
        ]
    },
    {
        "10": [
            'наименование района (города областного значения)',
            'наименование сельского округа или поселка',
            'наименование сельского населенного пункта',
            'статус села (опорное/ спутниковое)',
            'численность населения', 'примечания'
        ]
    },
    {
        "11": [
            'наименование района (города областного значения)',
            'наименование сельского округа или поселка',
            'наименование сельского населенного пункта',
            'статус снп (опорный/спутниковый)', 'статус села (опорное/ спутниковое)',
            'численность населения', 'примечания'
        ]
    }
];