<template>
    <div>
        <div class="actions-tab in-tab snp-actions">
            <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div>
                            <span class="left">
                                <i class="icon icon-info"></i>
                            </span>
                            <i class="icon icon-keyboard"></i>
                        </div>
                    </div>
                </template>
                <b-dropdown-text>
                    <div class="info-title">
                        <span>Мониторинг СНП</span>
                    </div>
                    <div class="info-text">
                        <p>Данный модуль предназначен для сбора и анализа данных по формам мониторинга СНП по
                            приказу Первого заместителя Премьер-Министра Республики Казахстан - Министра
                            регионального развития Республики Казахстан от 24 сентября 2013 года № 239/ОД. .</p>
                        <div class="info-subtitle">
                            <span>Конструктор</span>
                        </div>
                        <p>Раздел предназначен для настройки рабочей области, для постоянного отслеживания и
                            сравнения интересующих, специалиста показателей, как между собой, так-же и с
                            историческими данными.</p>
                    </div>
                </b-dropdown-text>
            </b-dropdown>
            <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                    <div class="btn-download">
                        <div @click="downloadFile">
                            <i class="icon icon-download" style="color: #01AC50; margin-left: 9px;"></i>
                            <span style="margin-right: 15px;">Скачать</span>
                        </div>
                        <i class="icon icon-keyboard"></i>
                    </div>
                </template>
                <b-dropdown-item @click="downloadFile">Констуктор</b-dropdown-item>
            </b-dropdown>
        </div>
        <div class="filter-container">
            <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                <template #button-content>
                    <span class="lc"
                    ><i class="icon icon-filter"></i> Фильтр</span
                    ><span class="rc"><i class="icon icon-keyboard"></i></span>
                </template>
                <div>
                    <div class="top-content">
                        <span>Параметры фильтра</span>
                        <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                    </div>
                    <!---------------Фильтр по шаблону-------------------->
                    <div class="filter-block">
                        <b-form-group :label="sgpDoc[0].name_ru">
                            <multiselect
                                v-model="templateVal"
                                :options="templateArr"
                                placeholder="Выберите шаблон"
                                :allow-empty="false"
                                :show-labels="false"
                                @input="handleTemplateSelect"
                                label="name"
                            />
                        </b-form-group>
                    </div>
                    <!---------------Фильтр по району-------------------->
                    <div class="filter-block">
                        <b-form-group :label="sgpDoc[1].name_ru">
                            <multiselect
                                v-model="regionVal"
                                :options="regionArr"
                                placeholder="Выберите регион"
                                :allow-empty="false"
                                :show-labels="false"
                                label="nameRu"
                            />
                        </b-form-group>
                    </div>
                    <!---------------Фильтр по сельскому округу-------------------->
                    <div class="filter-block">
                        <b-form-group :label="sgpDoc[2].name_ru">
                            <multiselect
                                v-model="districtVal"
                                :options="districtArr"
                                placeholder="Выберите сельский округ"
                                :allow-empty="false"
                                :show-labels="false"
                                label="nameRu"
                            />
                        </b-form-group>
                    </div>
                    <!---------------Фильтр по СНП-------------------->
                    <div class="filter-block">
                        <b-form-group :label="sgpDoc[3].name_ru">
                            <multiselect
                                v-model="snpVal"
                                :options="snpArr"
                                placeholder="Выберите СНП"
                                :allow-empty="false"
                                :show-labels="false"
                                label="nameRu"
                            />
                        </b-form-group>
                    </div>
                    <div class="filter-block">
                        <b-button variant="primary" @click="chgParams">Применить</b-button>
                        <b-button @click="resetFilter">Сбросить</b-button>
                    </div>
                </div>
            </b-dropdown>
            <div class="filter-actions">
                <!-- <b-button variant="primary" @click="unloadConstructor">Выгрузить</b-button> -->
                <b-button variant="light" @click="clearConstructor">Отменить</b-button>
                <b-button variant="primary" @click="saveTemplateModalVisible = true">Сохранить</b-button>
            </div>
        </div>
        <!---------Начало грид таблицы-------------->
        <b-progress
            variant="success"
            v-show="bar < 100"
            height="10px"
            :value="bar"
            striped
            animated
        ></b-progress>
        <div class="table-container">
            <div class="b-table-sticky-header table-responsive-true">
                <table
                    class="table b-table table-bordered b-table-no-border-collapse constructor-table"
                >
                    <thead>
                        <tr class="text-center">
                            <th rowspan="2" class="th-1" @click="this.toggleCollapseAll">
                                <img v-if="collapseAll" src="./icons/open.svg" alt="">
                                <img v-else src="./icons/closed.svg" alt="">
                            </th>
                            <th rowspan="2">Район</th>
                            <th rowspan="2">Сельский округ</th>
                            <th rowspan="2">СНП</th>
<!--                        <th rowspan="2">Потенциал СНП</th>-->
                            <template v-for="label of [0, 1, 2, 3, 4, 5, 6]">
                                <th :key="label" colspan="2"  v-if="currentHeads[label] === undefined" style="cursor: pointer" @click="reportTypeModal(label)">
                                    <span>Показатель</span>
                                    <i class="text-right icon icon-keyboard icon-rotate-90"></i>
                                </th>
                                <th :key="label" colspan="2" style="cursor: pointer" v-else>
                                   <span @click="reportTypeModal(label)">{{currentHeads[label].desc}}</span>
<!-- {{currentHeads[label].parentDesc}}    <i class="icon icon-close" style="float: right; cursor: pointer" @click="removeHead(label)"></i>-->
                                </th>
                            </template>
                        </tr>
                        <tr class="text-right">
                            <template v-for="yearLabel of yearLabelArr">
                                <th :key="yearLabel">
                                    <select v-model="yearHeads[yearLabel]" @change="selectYearHead(yearLabel, yearHeads[yearLabel] )">
                                        <option v-for="(year, yearIndx) in yearsArr"
                                            :key="'yearIndx' + yearIndx"
                                            :value="yearIndx">
                                            {{ year}}
                                        </option>
                                    </select>
                                </th>
                            </template>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item, itemIndex) in this.globalArr">
                            <tr
                                :key="`item${itemIndex}`"
                                v-bind:class="{'table-info': item.levels === 1}"
                                v-if="item.visible"
                                :style="{
                                        backgroundColor: item.typeLevel === 1 ? '#C3E7FF' : item.typeLevel === 2 ? '#E5FBFF' : '#FFFFFF'
                                    }"
                            >
                                <td class="text-center">
                                    <button
                                        v-if="item.typeLevel === 1 || item.typeLevel === 2"
                                        type="button"
                                        class="btn btn-secondary"
                                        @click="openChildren(item)"
                                    >
                                        <img v-if="item.open" src="./icons/open.svg" alt="">
                                        <img v-else src="./icons/closed.svg" alt="">
                                    </button>

                                </td>
                                <td :style="{fontWeight: item.typeLevel === 1 ? 'bold' : null}">{{item.region}}</td>
                                <td>{{item.ruralDistrict}}</td>
                                <td>{{item.snp}}</td>
                                <td class="text-center">{{item.year1}}</td>
                                <td class="text-center">{{item.year2}}</td>
                                <td class="text-center">{{item.year3}}</td>
                                <td class="text-center">{{item.year4}}</td>
                                <td class="text-center">{{item.year5}}</td>
                                <td class="text-center">{{item.year6}}</td>
                                <td class="text-center">{{item.year7}}</td>
                                <td class="text-center">{{item.year8}}</td>
                                <td class="text-center">{{item.year9}}</td>
                                <td class="text-center">{{item.year10}}</td>
                                <td class="text-center">{{item.year11}}</td>
                                <td class="text-center">{{item.year12}}</td>
                                <td class="text-center">{{item.year13}}</td>
                                <td class="text-center">{{item.year14}}</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <b-modal
            id="constructor-field"
            class="myModalProject"
            v-model="modalVisible"
            size="lg"
            centered
            content-class="modal-forms"
            hide-header
            hide-footer
            body-class="constructor-field"
        >
            <template #modal-title>
                Выберите показатель:
                <div style="padding: 7px 0px;">
                    <!-- @blur="searchHeaderText(searchHeader)" -->
                    <b-form-input  type="search" size="lg" v-model="searchHeader" placeholder="Поиск" class="mw-100">
                        <i class="icon icon-search"></i>
                    </b-form-input>
                </div>
            </template>
            <template v-for="(item, inx) of searchReportTypes">
                <div :key="inx">
                    <ul class="list-group list-group-">
                        <li
                            class="list-group-item"
                            @click="loadHeaderTypes(item.id, 0, 2021)"
                        >
                            <span v-if="item.id !== selectedType">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     class="bi bi-plus" viewBox="0 0 16 16">
                                    <path
                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                </svg>
                            </span>
                            <span v-else>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     class="bi bi-dash" viewBox="0 0 16 16">
                                    <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                                </svg>
                            </span>
                            {{ item.name }}
                        </li>
                    </ul>
                    <ul
                        class="list-group sub-report-type"
                        v-if="item.id === selectedType"
                    >
                        <template v-for="header of reportHeadersArr[item.id]">
                            <template v-if="header.visible === true">
                                <template v-if="item.name === 'Демография' && header.description.trim() === 'Численность населения'">
                                    <li
                                        :key="header.id"
                                        class="list-group-item"
                                        @click="handleHeaderClick(header)"
                                    >
                                        <span>{{`${"&emsp;".repeat(header.depth)} ${header.description}`}}</span>

                                    </li>
                                </template>
                                <template v-else-if="!checkInVisible(header.description.trim(), item.id)">
                                    <li
                                        :key="header.id"
                                        class="list-group-item"
                                        v-if="!checkOnClick(header.description, item.id)"
                                        @click="handleHeaderClick(header)"
                                    >
                                        <span>{{`${"&emsp;".repeat(header.depth)} ${header.description}`}}</span>

                                    </li>
                                    <li
                                        :key="header.id"
                                        class="list-group-item not-cursor"
                                        v-else-if="checkOnClick(header.description, item.id)"
                                        title="Невозможно выбрать"
                                    >
                                        <span>{{`${"&emsp;".repeat(header.depth)} ${header.description}`}}</span>
                                    </li>
                                </template>
                            </template>

                        </template>
                    </ul>
                </div>
            </template>
        </b-modal>
        <b-modal
            id="save-template-form"
            v-model="saveTemplateModalVisible"
            size="sm"
            centered
            title="Введите название шаблона"
            hide-footer
            hide-header
        >
            <b-form class="save-template-form">
                <b-form-group
                    label="Введите название шаблона"
                    label-for="template-name-input"
                >
                    <b-form-input size="sm" id="theme" v-model="templateName" ></b-form-input>
                </b-form-group>
                <b-button-group>
                    <b-button variant="success" @click="saveTemplate">Сохранить</b-button>
                    <b-button variant="light" @click="saveTemplateModalVisible = false">Отменить</b-button>
                </b-button-group>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import { MonitoringSnpApi } from '@/modules/monitoring-snp/api/MonitoringSnpApi';
import { isEmpty } from 'lodash';
import { notClickContructor, notView, notClickContructorNew, notViewNew } from './consts/notClick'
import { Ax } from "@/utils";

export default {
    name: 'constructor',
    props: ['me',  'regionArr', 'yearsArr'],
    data() {
        return {
            bar: 0,
            collapseAll: true,
            collapseArr: [],
            collapseArrSec: [],
            tableFields: [
                {
                    key: 'action',
                    label: ' ',
                    tdClass: 'td-side-style'
                },
                {
                    key: 'region',
                    label: 'Район',
                    tdClass: 'td-name-style',
                    rowSpan: 2
                },
                {
                    key: 'ruralDistrict',
                    label: 'Сельский округ',
                    tdClass: 'td-style'
                },
                {
                    key: 'snp',
                    label: 'СНП',
                    tdClass: 'td-style'
                },
                {
                    key: 'potentialSnp',
                    label: 'Потенциал СНП',
                    tdClass: 'td-style'
                },
                {
                    key: 'year1',
                    label: '2019',
                    tdClass: 'td-style'
                },
                {
                    key: 'year2',
                    label: '2020',
                    tdClass: 'td-style'
                },
                {
                    key: 'year3',
                    label: '2019',
                    tdClass: 'td-style'
                },
                {
                    key: 'year4',
                    label: '2020',
                    tdClass: 'td-style'
                },
                {
                    key: 'year5',
                    label: '',
                    tdClass: 'td-style'
                },
                {
                    key: 'year6',
                    label: '',
                    tdClass: 'td-style'
                },
                {
                    key: 'year7',
                    label: '',
                    tdClass: 'td-style'
                },
                {
                    key: 'year8',
                    label: '',
                    tdClass: 'td-style'
                },
                {
                    key: 'year9',
                    label: '',
                    tdClass: 'td-style'
                },
                {
                    key: 'year10',
                    label: '',
                    tdClass: 'td-style'
                },
                {
                    key: 'year11',
                    label: '',
                    tdClass: 'td-style'
                },
                {
                    key: 'year12',
                    label: '',
                    tdClass: 'td-style'
                },
                {
                    key: 'year13',
                    label: '',
                    tdClass: 'td-style'
                },
                {
                    key: 'year14',
                    label: '',
                    tdClass: 'td-style'
                },
                {
                    key: 'more',
                    label: ' ',
                    tdClass: 'td-side-style'
                }
            ],
            globalArr: [],
            tableRows: [],
            sgpDoc: [
                {
                    'name_ru': 'Шаблон',
                    'name_kk': 'Шаблон',
                    'name_en': 'Template'
                },
                {
                    'name_ru': 'Район',
                    'name_kk': 'Райой',
                    'name_en': 'District'
                },
                {
                    'name_ru': 'Сельский округ',
                    'name_kk': 'Сельский округ',
                    'name_en': 'Rural district'
                },
                {
                    'name_ru': 'СНП',
                    'name_kk': 'СНП',
                    'name_en': 'SNP'
                },
                {
                    'name_ru': 'Период',
                    'name_kk': 'Период',
                    'name_en': 'Period'
                }
            ],
            yearLabelArr: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
            regionVal: null,
            districtVal: null,
            districtArr: [],
            ruralDistrictVal: null,
            ruralDistrictArr: [],
            snpVal: null,
            snpArr: [],
            periodVal: null,
            periodArr: [],
            templateVal: null,
            templateArr: [],
            programArr: [],
            subProgramArr: [],
            editAvailable: false,
            modalVisible: false,
            saveTemplateModalVisible: false,
            subReportTypes: null,
            reportHeadersArr: {},
            selectedType: null,
            selectedHeaderIndx: null,
            childCounts: [],
            currentHeads: [],
            previousHeads: [],
            yearHeads: [],
            templateName: null,
            headValues: [],
            searchHeader: '',
            sections: [],
            regionInfo: [],
            globalArrTemp: [],
            snpValues: [],
            globalTypeId: null,
            searchReportTypes: [],
            reportTypes: [],
            templateId: null
        };
    },
    mounted() {
        this.loadTypes();
        this.loadTemplates();
    },
    watch: {
        regionVal: function() {
            this.districtVal = null;
            this.snpVal = null;
            if (this.regionVal?.nameRu === 'Все') {
                this.districtArr = [];
                this.snpArr = [];
            }
            this.regionVal?.code && this.loadDistricts();
        },
        districtVal: function () {
            this.snpVal = null;
            if (this.districtVal?.nameRu === 'Все') {
                this.snpArr = []
            }
            this.districtVal?.code && this.loadSnp();
        },
        searchHeader: function() {
            this.searchHeaderText(this.searchHeader);
        }
    },
    methods: {
        downloadFile() {
            if (isEmpty(this.templateVal)) {
                 this.makeToast('danger', 'Вы не сохранили шаблон или не выбрали шаблон из списка', 'Ошибка');
                return;
            }

            const templateId = this.templateVal.id;
            //this.templateArr.find(template => template.name === this.templateVal).id;
            const url = `/snp/api/v1/report/file/template?templateId=${templateId}`;
            Ax(
                {
                    url,
                    method: 'GET',
                    data: null,
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${this.$store.getters.token}`,
                    }
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${this.templateVal.name}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    console.log('err', error);
                }
            );

        },
        loadTypes() {
            MonitoringSnpApi
                .get('/snp/api/v1/report/types')
                .then(res => {
                    this.reportTypes = res.data;
                    this.searchReportTypes = res.data;
                })
                .catch(() => {
                });
        },
        handleTemplateSelect(e) {
            console.log(e);
            this.regionVal = this.districtVal = this.snpVal = null;
        },
        handleRegionSelect() {
            this.templateVal = null;
            this.districtVal = null;
            this.snpVal = null;
            this.regionVal?.code && this.regionVal?.nameRu !== 'Все' && this.loadDistricts();
        },
        checkOnClick(desc, itemId) {
            const item = notClickContructorNew[itemId - 1][itemId];
            return item.includes(desc.trim().toLowerCase());
        },
        checkInVisible(desc, itemId) {
            const item = notViewNew[itemId - 1][itemId];
            return item.includes(desc.trim().toLowerCase());
        },
        removeHead(columnIdx) {
            this.currentHeads[columnIdx] = undefined;
            // this.yearHeads = [];
            //console.log('globalArr', this.globalArr);
        },
        reportTypeModal(columnIdx) {
            this.selectedHeaderIndx = columnIdx;
            this.modalVisible = true;
        },
        loadTemplates() {
          MonitoringSnpApi.get('/snp/api/v1/report/templates')
            .then(res => {
                if (res.status === 200) {
                    this.templateArr = res.data;
                }
            })
            .catch(err => console.log(err));
        },
        setGlobalHeader(selTemplate, data) {
            const selectedTemplateInit = selTemplate; //this.templateArr.find((item) => item.name === selTemplate);
            if (selectedTemplateInit === undefined) {
                return;
            }
            data.forEach((item, indx) => {
                selectedTemplateInit.sections.map((section, sectionIndex) => {
                    this.currentHeads[sectionIndex] = {
                        idx: sectionIndex,
                        desc: section.head,
                        parentDesc: ''
                    };

                    switch (sectionIndex) {
                        case 0:
                            this.globalArr[indx].year1 = section.first.value;
                            this.globalArr[indx].year2 = section.second.value;
                            this.yearHeads[0] = section.first.year;
                            this.yearHeads[1] = section.second.year;
                            break;
                        case 1:
                            this.globalArr[indx].year3 = section.first.value;
                            this.globalArr[indx].year4 = section.second.value;
                            this.yearHeads[2] = section.first.year;
                            this.yearHeads[3] = section.second.year;
                            break;
                        case 2:
                            this.globalArr[indx].year5 = section.first.value;
                            this.globalArr[indx].year6 = section.second.value;
                            this.yearHeads[4] = section.first.year;
                            this.yearHeads[5] = section.second.year;
                            break;
                        case 3:
                            this.globalArr[indx].year7 = section.first.value;
                            this.globalArr[indx].year8 = section.second.value;
                            this.yearHeads[6] = section.first.year;
                            this.yearHeads[7] = section.second.year;
                            break;
                        case 4:
                            this.globalArr[indx].year9 = section.first.value;
                            this.globalArr[indx].year10 = section.second.value;
                            this.yearHeads[8] = section.first.year;
                            this.yearHeads[9] = section.second.year;
                            break;
                        case 5:
                            this.globalArr[indx].year11 = section.first.value;
                            this.globalArr[indx].year12 = section.second.value;
                            this.yearHeads[10] = section.first.year;
                            this.yearHeads[11] = section.second.year;
                            break;
                        case 6:
                            this.globalArr[indx].year13 = section.first.value;
                            this.globalArr[indx].year14 = section.second.value;
                            this.yearHeads[12] = section.first.year;
                            this.yearHeads[13] = section.second.year;
                            break;
                    };
                });
            });

        },
        loadTemplateConstructor() {
            const regions = null;
            const selectedTemplateInit = this.templateVal; //this.templateArr.find((item) => item.name === this.templateVal);
            selectedTemplateInit.sections.forEach((item) => {
                //console.log('item', item);
                if (item.first.values !== null) {
                    this.regions = item.first.values;
                }
            });
            if (this.regions === null) {
                this.makeToast('danger', 'Пользователь не прикрпелен к региону', 'Ошибка');
                return;
            }

            this.globalArr = [];
            this.currentHeads = [];
            this.yearHeads = [];

            this.regions.forEach((item, index) => {
                this.globalArr[index] = {
                    id: index,
                    parent: true,
                    levels: 1,
                    visible: true,
                    parent_id: 0,
                    open: true,
                    region: item.address.region,
                    ruralDistrict: item.address.district,
                    snp: item.address.snp,
                    code: item.address.code,
                    potentialSnp: '',
                    year1: '',
                    year2: '',
                    year3: '',
                    year4: '',
                    year5: '',
                    year6: '',
                    year7: '',
                    year8: '',
                    year9: '',
                    year10: '',
                    year11: '',
                    year12: '',
                    year13: '',
                    year14: ''
                };
            });

            this.sections = selectedTemplateInit.sections;
            //console.log('sections', this.sections);
            selectedTemplateInit.sections.forEach((item, sectionIndex) => {
                this.currentHeads[sectionIndex] = {
                    idx: sectionIndex,
                    desc: item.head,
                    parentDesc: ''
                };

                item.first.values.forEach((valItem, valIndex) => {
                    switch (sectionIndex) {
                        case 0:
                            this.globalArr[valIndex].year1 = valItem.value;
                            if (item.second.values) {
                                this.globalArr[valIndex].year2 = item.second.values[valIndex].value;
                            }
                            break;
                        case 1:
                            this.globalArr[valIndex].year3 = valItem.value;
                            if (item.second.values) {
                                this.globalArr[valIndex].year4 = item.second.values[valIndex].value;
                            }
                            break;
                        case 2:
                            this.globalArr[valIndex].year5 = valItem.value;
                            if (item.second.values) {
                                this.globalArr[valIndex].year6 = item.second.values[valIndex].value;
                            }
                            break;
                        case 3:
                            this.globalArr[valIndex].year7 = valItem.value;
                            if (item.second.values) {
                                this.globalArr[valIndex].year8 = item.second.values[valIndex].value;
                            }
                            break;
                        case 4:
                            this.globalArr[valIndex].year9 = valItem.value;
                            if (item.second.values) {
                                this.globalArr[valIndex].year10 = item.second.values[valIndex].value;
                            }
                            break;
                        case 5:
                            this.globalArr[valIndex].year11 = valItem.value;
                            if (item.second.values) {
                                this.globalArr[valIndex].year12 = item.second.values[valIndex].value;
                            }
                            break;
                        case 6:
                            this.globalArr[valIndex].year13 = valItem.value;
                            if (item.second.values) {
                                this.globalArr[valIndex].year14 = item.second.values[valIndex].value;
                            }
                            break;
                    };
                });


                const firstYear = this.yearsArr.findIndex((i) => i === item.first.year);
                const secondYear = this.yearsArr.findIndex((i) => i === item.second.year);
                switch (sectionIndex) {
                    case 0:
                        this.yearHeads[0] = firstYear;
                        this.yearHeads[1] = secondYear;
                        break;
                    case 1:
                        this.yearHeads[2] = firstYear;
                        this.yearHeads[3] = secondYear;
                        break;
                    case 2:
                        this.yearHeads[4] = firstYear;
                        this.yearHeads[5] = secondYear;
                        break;
                    case 3:
                        this.yearHeads[6] = firstYear;
                        this.yearHeads[7] = secondYear;
                        break;
                    case 4:
                        this.yearHeads[8] = firstYear;
                        this.yearHeads[9] = secondYear;
                        break;
                    case 5:
                        this.yearHeads[10] = firstYear;
                        this.yearHeads[11] = secondYear;
                        break;
                    case 6:
                        this.yearHeads[12] = firstYear;
                        this.yearHeads[13] = secondYear;
                        break;
                }
            });
        },
        loadGlobalArr() {
            this.programArr.forEach((program) => {
                this.globalArr.push({
                    ...program,
                    parent: false,
                    levels: 1,
                    visible: true,
                    parent_id: 0,
                    open: true
                });
                this.subProgramArr.forEach((subProgram) => {
                    subProgram.parent === program.id
                    && this.globalArr.push({
                        ...subProgram,
                        levels: 2,
                        visible: true,
                        open: true,
                        parent_id: program.id
                    });
                });
            });
        },
        loadHeadersArr(typeId, parent = {}) {
            const parentId = parent?.id || 0;
            this.subReportTypes.forEach((report) => {
                if (report.parentId === parentId && report.visible === true) {
                    this.childCounts[parentId] = this.childCounts[parentId]
                        ? this.childCounts[parentId] + 1
                        : 1;
                    this.reportHeadersArr[typeId].push({
                        ...report,
                        open: true,
                        visible: true,
                        childCount: 0
                    });
                    this.globalTypeId = typeId;
                    this.loadHeadersArr(typeId, report);
                }
            });
        },
        loadDistricts() {
            try {
                MonitoringSnpApi
                    .get(`/snp/api/v1/admin/dict-kato?parentId=${this.regionVal.id}`)
                    .then(res => {
                        this.districtArr = [{nameRu: 'Все'}, ...res.data];
                    })
                    .catch(err => console.log(err));
            } catch (error) {
                console.log(error);
            }
        },
        loadSnp() {
            try {
                MonitoringSnpApi
                    .get(`/snp/api/v1/admin/dict-kato?parentId=${this.districtVal.id}`)
                    .then(res => {
                        this.snpArr = [{nameRu: 'Все'}, ...res.data];
                    })
                    .catch(err => console.log(err));
            } catch (error) {
                console.log(error);
            }
        },
        resetFilter() {
            this.districtVal
                = this.regionVal
                = this.ruralDistrictVal
                = this.snpVal
                = this.formVal
                = this.templateVal
                = null;
            this.globalArr = [];
            this.headValues = [];
            this.currentHeads = [];
            this.sections = [];
            this.yearHeads = [];
            this.hideFiltersPanel();
        },
        reloadData() {
            this.globalArr = [];
            this.currentHeads = [];
        },
        hideFiltersPanel() {
            this.$refs.drop.hide(true);
        },
        loadHeaderTypes(typeId, parentId, selectedYear) {
            if (typeId === this.selectedType) {
                this.selectedType = null;
            } else {
                if (!this.reportHeadersArr.hasOwnProperty(typeId)) {
                    try {
                        MonitoringSnpApi
                            .get(`/snp/api/v1/report/headers/type?type=${typeId}&year=${selectedYear}`)
                            .then(res => {
                                this.subReportTypes = res.data;
                                this.reportHeadersArr[typeId] = [];
                                this.loadHeadersArr(typeId);
                                this.selectedType = typeId;
                            })
                            .catch(err => console.log(err));
                    } catch (error) {
                        console.log(error);
                    }
                } else {
                    this.selectedType = typeId;
                }
            }
        },
        loadHeaders() {
            try {
                MonitoringSnpApi
                    .get('/snp/api/v1/report/headers')
                    .then(res => this.typeArr = res.data)
                    .catch(err => console.log(err));
            } catch (error) {
                console.log(error);
            }
        },
        getCodeValue() {
            let code = undefined;
            if (this.regionVal !== null) {
                this.snpValues = [];
                code = this.regionVal?.code;
            }
            if (this.districtVal?.code) {
                this.snpValues = [];
                code = this.districtVal?.code;
                this.snpValues = this.snpArr;
            }
            if (this.snpVal?.code) {
                this.snpValues = [];
                this.snpValues.push(this.snpVal);
                code = this.snpVal?.code;
            }
            return code
        },
        chgParams() {

            if (this.templateVal === null && this.regionVal !== null && this.districtVal !== null && this.snpVal === null) {
                if (this.districtVal.nameRu !== 'Все') {
                    this.makeToast('danger', 'Вы не выбрали СНП', 'Ошибка');
                    return false;
                }
            }
            if (this.templateVal === null && this.regionVal !== null && this.districtVal === null) {
                if (this.regionVal.nameRu !== 'Все') {
                    this.makeToast('danger', 'Вы не выбрали сельский округ', 'Ошибка');
                    return false;
                }
            }

            if (this.templateVal !== null && this.regionVal === null && this.districtVal === null && this.snpVal === null) {
                this.templateVal && this.loadTemplateConstructor();
            } else {
                const body = {
                    code: this.getCodeValue(),
                    region: this.regionVal.nameRu,
                    district: this.districtVal?.nameRu,
                    cnp: this.snpVal?.nameRu,
                    year: new Date().getFullYear()
                };
                this.globalArr = [];
                this.yearHeads.forEach((yearHead, index) => {
                    this.selectYearHead(index, yearHead);
                });
                this.loadInfoSnp(body);
            }
            this.$refs.drop.hide(true);
        },
        loadAllTreeView(data) {
            const mainSnp = [];
            let districts = [];
            let snpList = []
            let info = {};
            MonitoringSnpApi.get(`/snp/api/v1/admin/all-child?code=${data.code}`)
                .then((res) => {
                    if (res.status === 200) {
                        mainSnp.push(res.data.filter((item) => item.type === 1));
                        mainSnp[0].forEach((firstItem) => {
                            info = {
                                id: firstItem.id,
                                parent: false,
                                levels: 1,
                                typeLevel: firstItem.type,
                                visible: true,
                                parent_id: firstItem.parentId,
                                open: false,
                                code: firstItem.code,
                                region: firstItem.nameRu,
                                ruralDistrict: '',
                                snp: '',
                                potentialSnp: '',
                                year1: '',
                                year2: '',
                                year3: '',
                                year4: '',
                                year5: '',
                                year6: '',
                                year7: '',
                                year8: '',
                                year9: '',
                                year10: '',
                                year11: '',
                                year12: '',
                                year13: '',
                                year14: ''
                            };
                            this.globalArr.push(info);
                            districts = res.data.filter((d) => d.parentId === firstItem.id);
                            districts.forEach((secondItem) => {
                                info = {
                                    id: secondItem.id,
                                    parent: false,
                                    levels: 1,
                                    typeLevel: secondItem.type,
                                    visible: false,
                                    parent_id: secondItem.parentId,
                                    open: false,
                                    code: secondItem.code,
                                    region: firstItem.nameRu,
                                    ruralDistrict: secondItem.nameRu,
                                    snp: '',
                                    potentialSnp: '',
                                    year1: '',
                                    year2: '',
                                    year3: '',
                                    year4: '',
                                    year5: '',
                                    year6: '',
                                    year7: '',
                                    year8: '',
                                    year9: '',
                                    year10: '',
                                    year11: '',
                                    year12: '',
                                    year13: '',
                                    year14: ''
                                };
                                this.globalArr.push(info);
                                snpList = res.data.filter((s) => s.parentId === secondItem.id);
                                snpList.forEach((thirdItem) => {
                                    info = {
                                        id: thirdItem.id,
                                        parent: false,
                                        levels: 1,
                                        typeLevel: thirdItem.type,
                                        visible: false,
                                        parent_id: thirdItem.parentId,
                                        open: false,
                                        code: thirdItem.code,
                                        region: firstItem.nameRu,
                                        ruralDistrict: secondItem.nameRu,
                                        snp: thirdItem.nameRu,
                                        potentialSnp: thirdItem.codePotenSnp,
                                        year1: '',
                                        year2: '',
                                        year3: '',
                                        year4: '',
                                        year5: '',
                                        year6: '',
                                        year7: '',
                                        year8: '',
                                        year9: '',
                                        year10: '',
                                        year11: '',
                                        year12: '',
                                        year13: '',
                                        year14: ''
                                    };
                                    this.globalArr.push(info);
                                });
                            });
                        });
                        this.globalArr && this.setGlobalHeader(this.templateVal, this.globalArr);
                    }
                })
                .catch((err) => {
                    console.log('err', err);
                })
                .finally(() => this.$refs.drop.hide(true));

        },
        loadInfoSnp(data) {
            let codeList = {};
            let info = {};
            if (data.district === 'Все' || data.region === 'Все') {
                this.loadAllTreeView(data);
            }
            if (data.cnp === 'Все') {
                info = {
                    id: this.districtVal.id,
                    parent: true,
                    levels: 1,
                    visible: true,
                    parent_id: this.districtVal.parentId,
                    open: true,
                    code: this.districtVal.code,
                    region: this.regionVal.nameRu,
                    ruralDistrict: this.districtVal.nameRu,
                    snp: null,
                    potentialSnp: '',
                    year1: '',
                    year2: '',
                    year3: '',
                    year4: '',
                    year5: '',
                    year6: '',
                    year7: '',
                    year8: '',
                    year9: '',
                    year10: '',
                    year11: '',
                    year12: '',
                    year13: '',
                    year14: ''
                };
                this.globalArr.push(info);

                codeList = this.snpArr;
                codeList.forEach((item, index) => {
                    if (item.nameRu !== 'Все') {
                        info = {
                            id: item.id,
                            parent: true,
                            levels: 1,
                            visible: true,
                            parent_id: item.parentId,
                            open: true,
                            code: item.code,
                            region: this.regionVal.nameRu,
                            ruralDistrict: data.district === 'Все' ? item.nameRu : this.districtVal.nameRu,
                            snp: data.cnp === 'Все' ? item.nameRu : null,
                            potentialSnp: '',
                            year1: '',
                            year2: '',
                            year3: '',
                            year4: '',
                            year5: '',
                            year6: '',
                            year7: '',
                            year8: '',
                            year9: '',
                            year10: '',
                            year11: '',
                            year12: '',
                            year13: '',
                            year14: ''
                        };
                        this.globalArr.push(info);
                    }
                });
            }
            if (data.cnp !== 'Все' && data.cnp !== undefined) {
                info = {
                    id: this.snpVal.id,
                    parent: true,
                    levels: 1,
                    visible: true,
                    parent_id: this.snpVal.parentId,
                    open: true,
                    code: this.snpVal.code,
                    region: this.regionVal.nameRu,
                    ruralDistrict: data.district === 'Все' ? this.snpVal.nameRu : this.districtVal.nameRu,
                    snp: this.snpVal.nameRu,
                    potentialSnp: '',
                    year1: '',
                    year2: '',
                    year3: '',
                    year4: '',
                    year5: '',
                    year6: '',
                    year7: '',
                    year8: '',
                    year9: '',
                    year10: '',
                    year11: '',
                    year12: '',
                    year13: '',
                    year14: ''
                };
                this.globalArr.push(info);

            }
            this.currentHeads.forEach((item, index) => {
                this.setYearHeads(index, false);
            });
        },
        toggleCollapseAll() {
            this.collapseAll = !this.collapseAll;
            this.regionOpen = this.collapseAll;
            this.globalArr.forEach(item => {
                if (item.typeLevel !== 1) {
                    if (this.collapseAll) {
                        item.visible = true;
                        item.open = true;
                    } else {
                        item.visible = false;
                        item.open = false;
                    }
                }
            });
            console.log('globalArr', this.globalArr);
        },
        async getRegionByCode(code) {
            await MonitoringSnpApi.getAsync(`/snp/api/v1/admin/address?code=${code}`)
                .then(resp => {
                   return  resp.data;
                })
                .catch(err => console.log(err));
        },
        openChildren(parent, bool) {
            parent.open = bool === undefined ? !parent.open : bool;
            for (const row of this.globalArr) {
                if (parent.id === row.parent_id) {
                    if ([1, 2].includes(row.typeLevel)) {
                        this.openChildren(row, parent.open);
                    }
                    row.visible = parent.open;
                }
            }
        },
        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        },
        handleEditButton() {
            this.editAvailable = true;
        },
        handleHeaderClick(header) {
            let head = {};
            let str = '';
            // console.log('header', header);
            // console.log('globalTypeId', this.globalTypeId);
            head = this.reportHeadersArr[header.type].filter((x) => x.id === header.parentId);
            if (head.length > 0) {
                if (head[0].parentId !== null) {
                    str = head[0].description + str;
                    //header.description = head[0].description + "/" + header.description;
                    head = this.reportHeadersArr[header.type].filter((x) => x.id === head[0].parentId);
                    if (head.length > 0) {
                        if (head[0].parentId !== null) {
                            str = head[0].description + '/' + str;
                        }
                    }
                }
            }
            const selectedHead = {
                idx: this.selectedHeaderIndx,
                id: header.id,
                desc: str === '' ? header.description : str + '/' + header.description,
                type: header.type,
                columnNumber: header.columnNumber,
                parentId: header.parentId
            };

            this.currentHeads[this.selectedHeaderIndx] = selectedHead;
            const sectionItem = {
                head: header.description,
                first: {},
                second: {}
            };
            this.sections[this.selectedHeaderIndx] = sectionItem;
            this.modalVisible = false;
            if (this.yearHeads[1]) {
                this.setYearHeads(this.selectedHeaderIndx, true);
            } else {
                this.setYearHeads(this.selectedHeaderIndx, false);
            }
        },
        setYearHeads(headIndex, isSetSecondIndex) {
            const code = this.getCodeValue();
            const body = {
                code: code,
                reportId: this.currentHeads[headIndex].type,
                year: null,
                columnNumber: this.currentHeads[headIndex].columnNumber,
                head: this.currentHeads[headIndex].desc
            };
            switch (headIndex) {
                case 0:
                    body.year = this.yearsArr[this.yearHeads[0]];
                    this.sendHeaderValue(body, headIndex, 0);
                    if (isSetSecondIndex) {
                        body.year = this.yearsArr[this.yearHeads[1]];
                        this.sendHeaderValue(body, headIndex, 1);
                    }
                    break;
                case 1:
                    if (this.currentHeads.find((i) => i.idx === 1) !== undefined
                        && this.currentHeads.find((i) => i.idx === 1) !== null) {
                        this.yearHeads[2] = this.yearHeads[0];
                        this.yearHeads[3] = this.yearHeads[1];
                        body.year = this.yearsArr[this.yearHeads[0]];
                        this.sendHeaderValue(body, headIndex, 2);
                        if (isSetSecondIndex) {
                            body.year = this.yearsArr[this.yearHeads[1]];
                            this.sendHeaderValue(body, headIndex, 3);
                        }
                    }
                    break;
                case 2:
                    if (this.currentHeads.find((i) => i.idx === 2) !== undefined
                        && this.currentHeads.find((i) => i.idx === 2) !== null) {
                        this.yearHeads[4] = this.yearHeads[0];
                        this.yearHeads[5] = this.yearHeads[1];
                        body.year = this.yearsArr[this.yearHeads[0]];
                        this.sendHeaderValue(body, headIndex, 4);
                        if (isSetSecondIndex) {
                            body.year = this.yearsArr[this.yearHeads[1]];
                            this.sendHeaderValue(body, headIndex, 5);
                        }
                    }
                    break;
                case 3:
                    if (this.currentHeads.find((i) => i.idx === 3) !== undefined
                        && this.currentHeads.find((i) => i.idx === 3) !== null) {
                        this.yearHeads[6] = this.yearHeads[0];
                        this.yearHeads[7] = this.yearHeads[1];
                        body.year = this.yearsArr[this.yearHeads[0]];
                        this.sendHeaderValue(body, headIndex, 6);
                        if (isSetSecondIndex) {
                            body.year = this.yearsArr[this.yearHeads[1]];
                            this.sendHeaderValue(body, headIndex, 7);
                        }
                    }
                    break;
                case 4:
                    if (this.currentHeads.find((i) => i.idx === 4) !== undefined
                        && this.currentHeads.find((i) => i.idx === 4) !== null) {
                        this.yearHeads[8] = this.yearHeads[0];
                        this.yearHeads[9] = this.yearHeads[1];
                        body.year = this.yearsArr[this.yearHeads[0]];
                        this.sendHeaderValue(body, headIndex, 8);
                        if (isSetSecondIndex) {
                            body.year = this.yearsArr[this.yearHeads[1]];
                            this.sendHeaderValue(body, headIndex, 9);
                        }
                    }
                    break;
                case 5:
                    if (this.currentHeads.find((i) => i.idx === 5) !== undefined
                        && this.currentHeads.find((i) => i.idx === 5) !== null) {
                        this.yearHeads[10] = this.yearHeads[0];
                        this.yearHeads[11] = this.yearHeads[1];
                        body.year = this.yearsArr[this.yearHeads[0]];
                        this.sendHeaderValue(body, headIndex, 10);
                        if (isSetSecondIndex) {
                            body.year = this.yearsArr[this.yearHeads[1]];
                            this.sendHeaderValue(body, headIndex, 11);
                        }
                    }
                    break;
                case 6:
                    if (this.currentHeads.find((i) => i.idx === 6) !== undefined
                        && this.currentHeads.find((i) => i.idx === 6) !== null) {
                        this.yearHeads[12] = this.yearHeads[0];
                        this.yearHeads[13] = this.yearHeads[1];
                        body.year = this.yearsArr[this.yearHeads[0]];
                        this.sendHeaderValue(body, headIndex, 12);
                        if (isSetSecondIndex) {
                            body.year = this.yearsArr[this.yearHeads[1]];
                            this.sendHeaderValue(body, headIndex, 13);
                        }
                    }
                    break;
                default:
                    break;
            }
        },
        clearConstructor() {
          this.currentHeads = [];
          this.yearHeads = [];
          this.globalArr = [];
        },
        unloadConstructor() {
            if (isEmpty(this.templateVal)) {
                 this.makeToast('danger', 'Вы не сохранили шаблон или не выбрали шаблон из списка', 'Ошибка');
                return;
            }
            const url = '/snp/api/v1/report/file/template';
            const templateId = this.templateVal.id;
            const fileName = this.templateVal.name.trim();
            console.log('templateId', templateId);
            Ax(
                    {
                        url: `${url}?templateId=${templateId}`,
                        method: 'GET',
                        data: null,
                        responseType: 'blob',
                        headers: {
                            Authorization: `Bearer ${this.$store.getters.token}`
                        }
                    },
                    (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `${fileName}.xlsx`);
                        document.body.appendChild(link);
                        link.click();
                    },
                    (error) => {
                        console.log('err', error);
                    }
            )
        },
        saveTemplate() {
            if (this.templateName.trim() === '') {
                this.makeToast('danger', 'Вы не ввели наименование шаблона', 'Ошибка');
                return false;
            }
            const body = {
                name: this.templateName,
                reportId: 1,
                sections: this.sections
            };

            for (let i = 0; i < body.sections.length; i++) {
                body.sections[i].head = this.currentHeads[i].desc;
            }

            MonitoringSnpApi
                .post('/snp/api/v1/report/template/save', body)
                .then(res => {
                    if (res.status === 200) {
                        this.makeToast('info', 'Шаблон успешно сохранен', 'Успешно');
                        this.saveTemplateModalVisible = false;
                        this.loadTemplates();
                    }
                })
                .catch(err => {
                    this.makeToast('danger', err, 'Ошибка!!!');
                });
        },
        getHeaderByYearIndex(yearIndex){
            let selectedHeaderIndx = 0;
            switch (yearIndex) {
                case 0:
                case 1:
                    selectedHeaderIndx = 0;
                    break;
                case 2:
                case 3:
                    selectedHeaderIndx = 1;
                    break;
                case 4:
                case 5:
                    selectedHeaderIndx = 2;
                    break;
                case 6:
                case 7:
                    selectedHeaderIndx = 3;
                    break;
                case 8:
                case 9:
                    selectedHeaderIndx = 4;
                    break;
                case 10:
                case 11:
                    selectedHeaderIndx = 5;
                    break;
                case 12:
                case 13:
                    selectedHeaderIndx = 6;
                    break;
                default:
                    break;
            };
            return selectedHeaderIndx;
        },
        selectYearHead(indx, selectedValue) {
            let isSelectedHead = true;
            let selectedHeaderIndx = 0;
            const isEven = indx % 2 === 0;
            let listYear = [];
            switch (indx) {
                case 0:
                case 1:
                    isSelectedHead = this.checkSelectedHead(0);
                    selectedHeaderIndx = 0;
                    break;
                case 2:
                case 3:
                    isSelectedHead = this.checkSelectedHead(1);
                    selectedHeaderIndx = 1;
                    break;
                case 4:
                case 5:
                    isSelectedHead = this.checkSelectedHead(2);
                    selectedHeaderIndx = 2;
                    break;
                case 6:
                case 7:
                    isSelectedHead = this.checkSelectedHead(3);
                    selectedHeaderIndx = 3;
                    break;
                case 8:
                case 9:
                    isSelectedHead = this.checkSelectedHead(4);
                    selectedHeaderIndx = 4;
                    break;
                case 10:
                case 11:
                    isSelectedHead = this.checkSelectedHead(5);
                    selectedHeaderIndx = 5;
                    break;
                case 12:
                case 13:
                    isSelectedHead = this.checkSelectedHead(6);
                    selectedHeaderIndx = 6;
                    break;
                default:
                    break;
            };
            if (isSelectedHead === false) {
                alert('Вы не выбрали Показатель!!!');
                this.yearHeads[indx] = undefined;
                return;
            };

            const code = this.getCodeValue();
            if (isEven) {
                listYear = this.yearLabelArr.filter((i) => i % 2 === 0);
            } else {
                listYear = this.yearLabelArr.filter((i) => i % 2 !== 0);
            }

            listYear.forEach((item) => {
                const header = this.getHeaderByYearIndex(item);
                if (this.currentHeads[header] !== undefined) {
                    this.yearHeads[item] =  selectedValue;
                    const body = {
                        code: code,
                        reportId: this.currentHeads[header].type,
                        year: this.yearsArr[selectedValue],
                        columnNumber: this.currentHeads[header].columnNumber,
                        head: this.currentHeads[header].desc
                    };
                    this.sendHeaderValue(body, this.currentHeads[header].idx, item);
                }
            });

        },
        checkSelectedHead(headIndx) {
            if (this.currentHeads[headIndx] === undefined) {
                return false;
            }
        },
        moreHeaderValueInit(resData, selectedHeaderIndx, selectedYearIndx, globalArr, sectionArr) {
            let globArrIndx = -1;
            const tempHeadValues = [];
            const tempGlobalArr = globalArr;
            let valueInfo = {};
            const valueInfos = [];
            resData.forEach((item) => {
                const getRegion = tempGlobalArr.filter((i) => i.code === item.code);
                if (getRegion.length > 0) {
                    valueInfo = {
                        value: item.body[0].text,
                        address: {
                            region: getRegion[0].region,
                            district: getRegion[0].ruralDistrict,
                            snp: getRegion[0].snp,
                            code: getRegion[0].code.toString()
                        }
                    };
                    valueInfos.push(valueInfo);
                }
            });

            for (let key = 0; key < resData.length; key++) {
                tempHeadValues.push({ ...resData[key] });
                if (!isEmpty(tempGlobalArr)) {
                    globArrIndx = tempGlobalArr.findIndex((item) => item.code === resData[key].code);
                };

                if (globArrIndx !== -1) {
                    switch (selectedYearIndx) {
                        case 0:
                            tempGlobalArr[globArrIndx].year1 = resData[key].body[0].text;
                            this.sections[selectedHeaderIndx].first = {
                                year: resData[key].year,
                                values: valueInfos,
                                columnNumber: resData[key].body[0].columnNumber
                            };
                            break;
                        case 1:
                            tempGlobalArr[globArrIndx].year2 = resData[key].body[0].text;
                            this.sections[selectedHeaderIndx].second = {
                                year: resData[key].year,
                                values: valueInfos,
                                columnNumber: resData[key].body[0].columnNumber
                            };
                            break;
                        case 2:
                            tempGlobalArr[globArrIndx].year3 = resData[key].body[0].text;
                            this.sections[selectedHeaderIndx].first = {
                                year: resData[key].year,
                                values: valueInfos,
                                columnNumber: resData[key].body[0].columnNumber
                            };
                            break;
                        case 3:
                            tempGlobalArr[globArrIndx].year4 = resData[key].body[0].text;
                            this.sections[selectedHeaderIndx].second = {
                                year: resData[key].year,
                                values: valueInfos,
                                columnNumber: resData[key].body[0].columnNumber
                            };
                            break;
                        case 4:
                            tempGlobalArr[globArrIndx].year5 = resData[key].body[0].text;
                            this.sections[selectedHeaderIndx].first = {
                                year: resData[key].year,
                                values: valueInfos,
                                columnNumber: resData[key].body[0].columnNumber
                            };
                            break;
                        case 5:
                            tempGlobalArr[globArrIndx].year6 = resData[key].body[0].text;
                            this.sections[selectedHeaderIndx].second = {
                                year: resData[key].year,
                                values: valueInfos,
                                columnNumber: resData[key].body[0].columnNumber
                            };
                            break;
                        case 6:
                            tempGlobalArr[globArrIndx].year7 = resData[key].body[0].text;
                            this.sections[selectedHeaderIndx].first = {
                                year: resData[key].year,
                                values: valueInfos,
                                columnNumber: resData[key].body[0].columnNumber
                            };
                            break;
                        case 7:
                            tempGlobalArr[globArrIndx].year8 = resData[key].body[0].text;
                            this.sections[selectedHeaderIndx].second = {
                                year: resData[key].year,
                                values: valueInfos,
                                columnNumber: resData[key].body[0].columnNumber
                            };
                            break;
                        case 8:
                            tempGlobalArr[globArrIndx].year9 = resData[key].body[0].text;
                            this.sections[selectedHeaderIndx].first = {
                                year: resData[key].year,
                                values: valueInfos,
                                columnNumber: resData[key].body[0].columnNumber
                            };
                            break;
                        case 9:
                            tempGlobalArr[globArrIndx].year10 = resData[key].body[0].text;
                            this.sections[selectedHeaderIndx].second = {
                                year: resData[key].year,
                                values: valueInfos,
                                columnNumber: resData[key].body[0].columnNumber
                            };
                            break;
                        case 10:
                            tempGlobalArr[globArrIndx].year11 = resData[key].body[0].text;
                            this.sections[selectedHeaderIndx].first = {
                                year: resData[key].year,
                                values: valueInfos,
                                columnNumber: resData[key].body[0].columnNumber
                            };
                            break;
                        case 11:
                            tempGlobalArr[globArrIndx].year12 = resData[key].body[0].text;
                            this.sections[selectedHeaderIndx].second = {
                                year: resData[key].year,
                                values: valueInfos,
                                columnNumber: resData[key].body[0].columnNumber
                            };
                            break;
                        case 12:
                            tempGlobalArr[globArrIndx].year13 = resData[key].body[0].text;
                            this.sections[selectedHeaderIndx].first = {
                                year: resData[key].year,
                                values: valueInfos,
                                columnNumber: resData[key].body[0].columnNumber
                            };
                            break;
                        case 13:
                            tempGlobalArr[globArrIndx].year14 = resData[key].body[0].text;
                            this.sections[selectedHeaderIndx].second = {
                                year: resData[key].year,
                                values: valueInfos,
                                columnNumber: resData[key].body[0].columnNumber
                            };
                            break;
                        default:
                            break;
                    }
                }
            }

            this.headValues.push({...tempHeadValues});
            this.globalArr.push({...tempGlobalArr});

        },
        sendHeaderValue(body, selectedHeaderIndx, selectedYearIndx) {
            let info = null;
            let globArrIndx = -1;
            let currentRegionInfo = null;
            let valueInfo = {};
            const valueInfos = [];
            try {
                if (selectedHeaderIndx !== undefined && selectedYearIndx !== undefined) {
                    MonitoringSnpApi
                        .post(`/snp/api/v1/report/headers/value`, body)
                        .then(res => {
                            if (res.status === 200) {
                                if (res.data === []) {
                                    res.data.code = body.code;
                                };
                                if (res.data.length === 1) {
                                    this.headValues.push({ ...res.data });
                                    if (!isEmpty(this.globalArr)) {
                                        globArrIndx = this.globalArr.findIndex((item) => item.code === body.code);
                                    }
                                    valueInfo = {
                                        value: res.data[0].body[0].text,
                                        address: {
                                            region: this.globalArr[globArrIndx].region,
                                            district: this.globalArr[globArrIndx].ruralDistrict,
                                            snp: this.globalArr[globArrIndx].snp,
                                            code: this.globalArr[globArrIndx].code.toString()
                                        }
                                    };
                                    valueInfos.push(valueInfo);

                                    switch (selectedYearIndx) {
                                        case 0:
                                            this.globalArr[globArrIndx].year1 = res.data[0].body[0].text;
                                            this.sections[selectedHeaderIndx].first = {
                                                year: res.data[0].year,
                                                values: valueInfos,
                                                columnNumber: res.data[0].body[0].columnNumber
                                            };
                                            break;
                                        case 1:
                                            this.globalArr[globArrIndx].year2 = res.data[0].body[0].text;
                                            this.sections[selectedHeaderIndx].second = {
                                                year: res.data[0].year,
                                                values: valueInfos,
                                                columnNumber: res.data[0].body[0].columnNumber
                                            };
                                            break;
                                        case 2:
                                            this.globalArr[globArrIndx].year3 = res.data[0].body[0].text;
                                            this.sections[selectedHeaderIndx].first = {
                                                year: res.data[0].year,
                                                values: valueInfos,
                                                columnNumber: res.data[0].body[0].columnNumber
                                            };
                                            break;
                                        case 3:
                                            this.globalArr[globArrIndx].year4 = res.data[0].body[0].text;
                                            this.sections[selectedHeaderIndx].second = {
                                                year: res.data[0].year,
                                                values: valueInfos,
                                                columnNumber: res.data[0].body[0].columnNumber
                                            };
                                            break;
                                        case 4:
                                            this.globalArr[globArrIndx].year5 = res.data[0].body[0].text;
                                            this.sections[selectedHeaderIndx].first = {
                                                year: res.data[0].year,
                                                values: valueInfos,
                                                columnNumber: res.data[0].body[0].columnNumber
                                            };
                                            break;
                                        case 5:
                                            this.globalArr[globArrIndx].year6 = res.data[0].body[0].text;
                                            this.sections[selectedHeaderIndx].second = {
                                                year: res.data[0].year,
                                                values: valueInfos,
                                                columnNumber: res.data[0].body[0].columnNumber
                                            };
                                            break;
                                        case 6:
                                            this.globalArr[globArrIndx].year7 = res.data[0].body[0].text;
                                            this.sections[selectedHeaderIndx].first = {
                                                year: res.data[0].year,
                                                values: valueInfos,
                                                columnNumber: res.data[0].body[0].columnNumber
                                            };
                                            break;
                                        case 7:
                                            this.globalArr[globArrIndx].year8 = res.data[0].body[0].text;
                                            this.sections[selectedHeaderIndx].second = {
                                                year: res.data[0].year,
                                                values: valueInfos,
                                                columnNumber: res.data[0].body[0].columnNumber
                                            };
                                            break;
                                        case 8:
                                            this.globalArr[globArrIndx].year9 = res.data[0].body[0].text;
                                            this.sections[selectedHeaderIndx].first = {
                                                year: res.data[0].year,
                                                values: valueInfos,
                                                columnNumber: res.data[0].body[0].columnNumber
                                            };
                                            break;
                                        case 9:
                                            this.globalArr[globArrIndx].year10 = res.data[0].body[0].text;
                                            this.sections[selectedHeaderIndx].second = {
                                                year: res.data[0].year,
                                                values: valueInfos,
                                                columnNumber: res.data[0].body[0].columnNumber
                                            };
                                            break;
                                        case 10:
                                            this.globalArr[globArrIndx].year11 = res.data[0].body[0].text;
                                            this.sections[selectedHeaderIndx].first = {
                                                year: res.data[0].year,
                                                values: valueInfos,
                                                columnNumber: res.data[0].body[0].columnNumber
                                            };
                                            break;
                                        case 11:
                                            this.globalArr[globArrIndx].year12 = res.data[0].body[0].text;
                                            this.sections[selectedHeaderIndx].second = {
                                                year: res.data[0].year,
                                                values: valueInfos,
                                                columnNumber: res.data[0].body[0].columnNumber
                                            };
                                            break;
                                        case 12:
                                            this.globalArr[globArrIndx].year13 = res.data[0].body[0].text;
                                            this.sections[selectedHeaderIndx].first = {
                                                year: res.data[0].year,
                                                values: valueInfos,
                                                columnNumber: res.data[0].body[0].columnNumber
                                            };
                                            break;
                                        case 13:
                                            this.globalArr[globArrIndx].year14 = res.data[0].body[0].text;
                                            this.sections[selectedHeaderIndx].second = {
                                                year: res.data[0].year,
                                                values: valueInfos,
                                                columnNumber: res.data[0].body[0].columnNumber
                                            };
                                            break;
                                        default:
                                            break;
                                    }
                                } else if (res.data.length > 1) {
                                    this.moreHeaderValueInit(res.data, selectedHeaderIndx, selectedYearIndx, this.globalArr, this.sections);
                                }
                            }
                        })
                        .catch(err => {
                            console.log('err', err);
                        });
                } else {
                    info = {
                        id: 1,
                        parent: true,
                        levels: 1,
                        visible: true,
                        parent_id: 0,
                        open: true,
                        code: this.getCodeValue(),
                        region: this.regionVal,
                        ruralDistrict: this.districtVal,
                        snp: this.snp,
                        potentialSnp: '',
                        year1: '',
                        year2: '',
                        year3: '',
                        year4: '',
                        year5: '',
                        year6: '',
                        year7: '',
                        year8: '',
                        year9: '',
                        year10: '',
                        year11: '',
                        year12: '',
                        year13: '',
                        year14: ''
                    };
                    if (this.globalArr.length === 0) {
                        globArrIndx = 0;
                    }
                    this.globalArr.push(info);

                    this.snpValues.forEach((item, index) => {
                        MonitoringSnpApi.get(`/snp/api/v1/admin/address?code=${item.code}`)
                            .then(resp => {
                                currentRegionInfo = resp.data;
                                info = {
                                    id: 1,
                                    parent: true,
                                    levels: 1,
                                    visible: true,
                                    parent_id: 0,
                                    open: true,
                                    code: item.code,
                                    region: currentRegionInfo.region,
                                    ruralDistrict: currentRegionInfo.district,
                                    snp: currentRegionInfo.snp,
                                    potentialSnp: '',
                                    year1: '',
                                    year2: '',
                                    year3: '',
                                    year4: '',
                                    year5: '',
                                    year6: '',
                                    year7: '',
                                    year8: '',
                                    year9: '',
                                    year10: '',
                                    year11: '',
                                    year12: '',
                                    year13: '',
                                    year14: ''
                                };
                                if (this.globalArr.length === 0) {
                                    globArrIndx = 0;
                                }
                                this.globalArr.push(info);
                            })
                            .catch(err => console.log(err));
                    });
                }
            } catch (error) {
                console.log('error', error);
            }
        },
        searchHeaderText(searchText) {
            if (!isEmpty(searchText)) {
                const arrFilter = this.searchReportTypes;
                const item = arrFilter.filter(item => item.description.toLowerCase().includes(searchText.toLowerCase()));
                this.searchReportTypes = item;
            } else {
                this.searchReportTypes = this.reportTypes;
            }
        },
        makeToast(variant = null, tostbody, title) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                autoHideDelay: 4000,
                solid: true
            });
        },
    }
};
</script>
<style scoped>
img {
    cursor: pointer;
}
.constructor-table > tbody > tr:first-child > td {
    font-weight: bold;
}

.save-template-form > label {
    font-size: 14px;
    font-weight: 600;
}

li {
    cursor: pointer;
}

li:hover {
    background-color: #f6f6f6;
}

.not-cursor {
    cursor: help
}

</style>
