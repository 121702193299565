export const formAnalysisHeaders = {
    14: [
        [
            {
                title: '',
                colspan: 1,
                rowspan: 3
            },
            {
                title: 'Регион',
                key: 'region',
                colspan: 2,
                rowspan: 3
            },
            {
                title: 'Количество СНП',
                key: 'cnp',
                colspan: 1,
                rowspan: 3
            },
            {
                title: 'СНП без населения',
                key: 'snpPopulation0',
                colspan: 1,
                rowspan: 3
            },
            {
                title: 'Численность населения',
                key: 'population',
                colspan: 1,
                rowspan: 3
            },
            {
                title: 'в том числе',
                colspan: 12,
                rowspan: 1
            }
        ],
        [
            {
                title: 'до 50 чел.',
                colspan: 2,
                rowspan: 1
            },
            {
                title: 'от 50 до 200 чел.',
                colspan: 2,
                rowspan: 1
            },
            {
                title: 'от 200 до 500 чел.',
                colspan: 2,
                rowspan: 1
            },
            {
                title: 'от 500 до 1000 чел.',
                colspan: 2,
                rowspan: 1
            },
            {
                title: 'от 1000 до 5000 чел.',
                colspan: 2,
                rowspan: 1
            },
            {
                title: 'от 5000 чел.',
                colspan: 2,
                rowspan: 1
            }
        ],
        [
            {
                title: 'Кол-во СНП',
                key: 'countPopulation50',
                colspan: 1,
                rowspan: 1
            },
            {
                title: 'Численность населения',
                key: 'sumPopulation50',
                colspan: 1,
                rowspan: 1
            },
            {
                title: 'Кол-во СНП',
                key: 'countPopulation50and200',
                colspan: 1,
                rowspan: 1
            },
            {
                title: 'Численность населения',
                key: 'sumPopulation50and200',
                colspan: 1,
                rowspan: 1
            },
            {
                title: 'Кол-во СНП',
                key: 'countPopulation200and500',
                colspan: 1,
                rowspan: 1
            },
            {
                title: 'Численность населения',
                key: 'sumPopulation200and500',
                colspan: 1,
                rowspan: 1
            },
            {
                title: 'Кол-во СНП',
                key: 'countPopulation500and1000',
                colspan: 1,
                rowspan: 1
            },
            {
                title: 'Численность населения',
                key: 'sumPopulation500and1000',
                colspan: 1,
                rowspan: 1
            },
            {
                title: 'Кол-во СНП',
                key: 'countPopulation1000and5000',
                colspan: 1,
                rowspan: 1
            },
            {
                title: 'Численность населения',
                key: 'sumPopulation1000and5000',
                colspan: 1,
                rowspan: 1
            },
            {
                title: 'Кол-во СНП',
                key: 'countPopulationOver5000',
                colspan: 1,
                rowspan: 1
            },
            {
                title: 'Численность населения',
                key: 'sumPopulationOver5000',
                colspan: 1,
                rowspan: 1
            }
        ]
    ],
    18: [
        [
            {
                title: '',
                colspan: 1,
                rowspan: 2
            },
            {
                title: 'Наименование области',
                colspan: 2,
                rowspan: 2,
                key: 'region'
            },
            {
                title: 'Количество СНП',
                colspan: 1,
                rowspan: 2,
                key: 'cnp'
            },
            {
                title: 'Кол-во СНП, где имеются объекты образования',
                colspan: 1,
                rowspan: 2,
                key: 'sumSchool'
            },
            {
                title: 'в том числе',
                colspan: 3,
                rowspan: 1
            },
            {
                title: 'Кол-во СНП где отсутсвуют объекты образования',
                colspan: 1,
                rowspan: 2,
                key: 'sumSchoolZero'
            },
            {
                title: 'из них по гос.нормативу должны быть',
                colspan: 1,
                rowspan: 2,
                key: 'stateStandard'
            },
            {
                title: 'СНП, где постройки свыше 40 лет',
                colspan: 1,
                rowspan: 2,
                key: 'sum40Years'
            },
            {
                title: 'СНП, где свыше 10 лет после кап-ремонта',
                colspan: 1,
                rowspan: 2,
                key: 'sum10Years'
            },
            {
                title: 'Кол-во СНП, где имеются объекты образования %',
                colspan: 1,
                rowspan: 2,
                key: 'sumSchoolPercent'
            },
            {
                title: 'из них по гос.нормативу должны быть %',
                colspan: 1,
                rowspan: 2,
                key: 'sumTypePercent'
            }
        ],
        [
            {
                title: 'Расположение в приспособленных помещениях',
                colspan: 1,
                rowspan: 1,
                key: 'sumAdapted'
            },
            {
                title: 'Наличие школ и их соотвествие гос.нормативам',
                colspan: 1,
                rowspan: 1,
                key: 'sumType'
            },
            {
                title: 'Наличие школ и их несоотвествие гос.нормативам',
                colspan: 1,
                rowspan: 1,
                key: 'sumEmergency'
            }
        ]
    ],
    19: [
        [
            {
                title: '',
                colspan: 1,
                rowspan: 2
            },
            {
                title: 'Наименование области',
                colspan: 2,
                rowspan: 2,
                key: 'region'
            },
            {
                title: 'Количество СНП',
                colspan: 1,
                rowspan: 2,
                key: 'cnp'
            },
            {
                title: 'Количество СНП, где имеются объекты здравоохранения',
                colspan: 1,
                rowspan: 2,
                key: 'healthFacilities'
            },
            {
                title: 'в том числе',
                colspan: 3,
                rowspan: 1
            },
            {
                title: 'Количество СНП, где отсутсвуют объекты здравоохранения',
                colspan: 1,
                rowspan: 2,
                key: 'noHealthFacilities'
            },
            {
                title: 'в том числе',
                colspan: 2,
                rowspan: 1
            },
            {
                title: 'СНП, где постройки свыше 40 лет',
                colspan: 1,
                rowspan: 2,
                key: 'moreThan40Years'
            },
            {
                title: 'СНП, где свыше 10 лет после кап-ремонта',
                colspan: 1,
                rowspan: 2,
                key: 'moreThan10Years'
            }
        ],
        [
            {
                title: 'Расположенные в приспособленных помещениях',
                colspan: 1,
                rowspan: 1,
                key: 'sumAdapted'
            },
            {
                title: 'Наличие объектов здравоохранения и их сооветствие гос.нормативам',
                colspan: 1,
                rowspan: 1,
                key: 'sumType'
            },
            {
                title: 'Наличие объектов здравоохранения и их несооветствие гос.нормативам',
                colspan: 1,
                rowspan: 1,
                key: 'sumEmergency'
            },
            {
                title: 'по гос.нормативу должны быть',
                colspan: 1,
                rowspan: 1,
                key: 'stateStandard'
            },
            {
                title: 'Мед.работник без содержания отдельного помещения',
                colspan: 1,
                rowspan: 1,
                key: 'medicalPersonal'
            }
        ]
    ],
    15: [
        [
            {
                title: '',
                colspan: 1,
                rowspan: 3
            },
            {
                title: 'Наименование области',
                colspan: 2,
                rowspan: 3,
                key: 'region'
            },
            {
                title: 'Количество СНП',
                colspan: 1,
                rowspan: 3,
                key: 'cnp'
            },
            {
                title: 'Численность населения',
                colspan: 1,
                rowspan: 3,
                key: 'population'
            },
            {
                title: 'Тип системы питьевого водоснабжения',
                colspan: 12,
                rowspan: 1
            }
        ],
        [
            {
                title: 'Централизованное водоснабжение (водопровод)',
                colspan: 4,
                rowspan: 1
            },
            {
                title: 'Децентрализованное водоснабжение (скважины, пункт раздачи воды, колодцы и т.д.)',
                colspan: 4,
                rowspan: 1
            },
            {
                title: 'Привозная вода',
                colspan: 4,
                rowspan: 1
            }
        ],
        [
            {
                title: 'Кол-во СНП',
                colspan: 1,
                rowspan: 1,
                key: 'centralized'
            },
            {
                title: '% от общ. кол-ва СНП',
                colspan: 1,
                rowspan: 1,
                key: 'centralizedPercent'
            },
            {
                title: 'Числен. насел.',
                colspan: 1,
                rowspan: 1,
                key: 'centralizedPopulation'
            },
            {
                title: '% от общ. числ. населения',
                colspan: 1,
                rowspan: 1,
                key: 'centralizedPopulationPercent'
            },
            {
                title: 'Кол-во СНП',
                colspan: 1,
                rowspan: 1,
                key: 'decentralized'
            },
            {
                title: '% от общ. кол-ва СНП',
                colspan: 1,
                rowspan: 1,
                key: 'decentralizedPercent'
            },
            {
                title: 'Числен. насел.',
                colspan: 1,
                rowspan: 1,
                key: 'decentralizedPopulation'
            },
            {
                title: '% от общ. числ. населения',
                colspan: 1,
                rowspan: 1,
                key: 'decentralizedPopulationPercent'
            },
            {
                title: 'Кол-во СНП',
                colspan: 1,
                rowspan: 1,
                key: 'imported'
            },
            {
                title: '% от общ. кол-ва СНП',
                colspan: 1,
                rowspan: 1,
                key: 'importedPercent'
            },
            {
                title: 'Числен. насел.',
                colspan: 1,
                rowspan: 1,
                key: 'importedPopulation'
            },
            {
                title: '% от общ. числ. населения',
                colspan: 1,
                rowspan: 1,
                key: 'importedPopulationPercent'
            }
        ]
    ],
    17: [
        [
            {
                title: '',
                colspan: 1,
                rowspan: 3
            },
            {
                title: 'Наименование области',
                colspan: 2,
                rowspan: 3,
                key: 'region'
            },
            {
                title: 'Количество СНП',
                colspan: 1,
                rowspan: 3,
                key: 'cnp'
            },
            {
                title: 'из них кол-во СНП, где автодороги до райцентра имеет поктрытие:',
                colspan: 12,
                rowspan: 2
            },
            {
                title: 'Кол-во СНП, где автодорога до райцентра в неудовлит. состоянии',
                colspan: 1,
                rowspan: 3,
                key: 'roadCondition'
            },
            {
                title: 'Общая протяженность дорог местного значения, км',
                colspan: 1,
                rowspan: 3,
                key: 'totalLength'
            },
            {
                title: 'Требуется',
                colspan: 8,
                rowspan: 1
            }
        ],
        [
            {
                title: 'капитальный ремонт',
                colspan: 4,
                rowspan: 1
            },
            {
                title: 'средний ремонт',
                colspan: 4,
                rowspan: 1
            }
        ],
        [
            {
                title: 'твердое',
                colspan: 2,
                rowspan: 1,
                key: 'hard'
            },
            {
                title: '%',
                colspan: 2,
                rowspan: 1,
                key: 'hardPercent'
            },
            {
                title: 'грунтовое',
                colspan: 2,
                rowspan: 1,
                key: 'ground'
            },
            {
                title: '%',
                colspan: 2,
                rowspan: 1,
                key: 'groundPercent'
            },
            {
                title: 'бездорожье',
                colspan: 2,
                rowspan: 1,
                key: 'offRoad'
            },
            {
                title: '%',
                colspan: 2,
                rowspan: 2,
                key: 'offRoadPercent'
            },
            {
                title: 'км',
                colspan: 2,
                rowspan: 1,
                key: 'majorOverhaul'
            },
            {
                title: '%',
                colspan: 2,
                rowspan: 1,
                key: 'majorOverhaulPercent'
            },
            {
                title: 'км',
                colspan: 2,
                rowspan: 1,
                key: 'mediumRepair'
            },
            {
                title: '%',
                colspan: 2,
                rowspan: 1,
                key: 'mediumRepairPercent'
            }
        ]
    ],
    16: [
        [
            {
                title: '',
                colspan: 1,
                rowspan: 2
            },
            {
                title: 'Наименование области',
                colspan: 2,
                rowspan: 2,
                key: 'region'
            },
            {
                title: 'Всего СНП',
                colspan: 1,
                rowspan: 2,
                key: 'cnp'
            },
            {
                title: 'СНП без населения',
                colspan: 1,
                rowspan: 2,
                key: 'populationZero'
            },
            {
                title: 'Количество сельских населенных пунктов (СНП)',
                colspan: 12,
                rowspan: 1
            }
        ],
        [
            {
                title: 'обеспечены стационарной почтовой связью',
                colspan: 1,
                rowspan: 1,
                key: 'stationary'
            },
            {
                title: '% к общему кол-ву СНП',
                colspan: 1,
                rowspan: 1,
                key: 'stationaryPercent'
            },
            {
                title: 'телефони-зированные',
                colspan: 1,
                rowspan: 1,
                key: 'telephoned'
            },
            {
                title: 'не телефони-зированные',
                colspan: 1,
                rowspan: 1,
                key: 'notTelephoned'
            },
            {
                title: 'электри-фицированные',
                colspan: 1,
                rowspan: 1,
                key: 'electrified'
            },
            {
                title: 'не электри-фицированные',
                colspan: 1,
                rowspan: 1,
                key: 'notElectrified'
            },
            {
                title: 'обеспеченные центра-лизованным газо-снабжением',
                colspan: 1,
                rowspan: 1,
                key: 'centralizedGas'
            },
            {
                title: '% к общему кол-ву СНП',
                colspan: 1,
                rowspan: 1,
                key: 'centralizedGasPercent'
            },
            {
                title: 'не обеспеченные центра-лизованным газо-снабжением',
                colspan: 1,
                rowspan: 1,
                key: 'notCentralizedGas'
            },
            {
                title: '% к общему кол-ву СНП',
                colspan: 1,
                rowspan: 1,
                key: 'notCentralizedGasPercent'
            },
            {
                title: 'обеспеченные ШПД к Интернету',
                colspan: 1,
                rowspan: 1,
                key: 'internet'
            },
            {
                title: '% к общему кол-ву СНП',
                colspan: 1,
                rowspan: 1,
                key: 'internetPercent'
            }
        ]
    ],
    20: [
        [
            {
                title: '',
                colspan: 1,
                rowspan: 3
            },
            {
                title: 'Регион',
                key: 'region',
                colspan: 2,
                rowspan: 3
            },
            {
                title: 'Количество СНП',
                key: 'cnp',
                colspan: 1,
                rowspan: 3
            },
            {
                title: 'в том числе обеспечены:',
                colspan: 12,
                rowspan: 1
            }
        ],
        [
            {
                title: 'Объектами культуры',
                key: 'sumCultureFacilities',
                colspan: 2,
                rowspan: 2
            },
            {
                title: 'в том числе',
                colspan: 2,
                rowspan: 1
            },
            {
                title: 'Объектами спорта',
                key: 'sumSportsFacilities',
                colspan: 2,
                rowspan: 2
            },
            {
                title: 'в том числе',
                colspan: 4,
                rowspan: 1
            },
            {
                title: 'Объектами туризма',
                key: 'sumTourism',
                colspan: 2,
                rowspan: 2
            }
        ],
        [
            {
                title: 'Культурно-досуговыми предприятиями (клубы и ДК)',
                key: 'sumClubAndCultureHouse',
                colspan: 2,
                rowspan: 1
            },
            {
                title: 'ДЮСШ',
                key: 'sumYouthSportsSchool',
                colspan: 2,
                rowspan: 1
            },
            {
                title: 'Спорт. зал',
                key: 'sumSportCenter',
                colspan: 2,
                rowspan: 1
            }
        ]
    ]
};

export const tableFieldsOrder = {
    14: [
        { title: 'region', colspan: 2 },
        { title: 'cnp', colspan: 1 },
        { title: 'sumPopulation0', colspan: 1 },
        { title: 'population', colspan: 1 },
        { title: 'countPopulation50', class: 'yellow' },
        { title: 'sumPopulation50', class: 'yellow' },
        'countPopulation50and200',
        'sumPopulation50and200',
        { title: 'countPopulation200and500', class: 'yellow' },
        { title: 'sumPopulation200and500', class: 'yellow' },
        'countPopulation500and1000',
        'sumPopulation500and1000',
        { title: 'countPopulation1000and5000', class: 'yellow' },
        { title: 'sumPopulation1000and5000', class: 'yellow' },
        'countPopulationOver5000',
        'sumPopulationOver5000'
    ],
    15: [
        { title: 'region', colspan: 2 },
        { title: 'cnp', colspan: 1 },
        { title: 'population', colspan: 1 },
        { title: 'centralized', class: 'yellow' },
        { title: 'centralizedPercent', class: 'yellow' },
        { title: 'centralizedPopulation', class: 'yellow' },
        { title: 'centralizedPopulationPercent', class: 'yellow' },
        'decentralized',
        'decentralizedPercent',
        'decentralizedPopulation',
        'decentralizedPopulationPercent',
        { title: 'imported', class: 'yellow' },
        { title: 'importedPercent', class: 'yellow' },
        { title: 'importedPopulation', class: 'yellow' },
        { title: 'importedPopulationPercent', class: 'yellow' }
    ],
    16: [
        { title: 'region', colspan: 2 },
        { title: 'cnp', colspan: 1 },
        { title: 'populationZero', colspan: 1 },
        { title: 'stationary', class: 'yellow' },
        { title: 'stationaryPercent', class: 'yellow' },
        'telephoned',
        'notTelephoned',
        { title: 'electrified', class: 'yellow' },
        { title: 'notElectrified', class: 'yellow' },
        'centralizedGas',
        'centralizedGasPercent',
        { title: 'notCentralizedGas', class: 'yellow' },
        { title: 'notCentralizedGasPercent', class: 'yellow' },
        'internet',
        'internetPercent'
    ],
    17: [
        { title: 'region', colspan: 2 },
        { title: 'cnp', colspan: 1 },
        { title: 'hard', colspan: 2, class: 'yellow' },
        { title: 'hardPercent', colspan: 2, class: 'yellow' },
        { title: 'ground', colspan: 2, class: 'yellow' },
        { title: 'groundPercent', colspan: 2, class: 'yellow' },
        { title: 'offRoad', colspan: 2, class: 'yellow' },
        { title: 'offRoadPercent', colspan: 2, class: 'yellow' },
        'roadCondition',
        'totalLength',
        { title: 'majorOverhaul', colspan: 2, class: 'yellow' },
        { title: 'majorOverhaulPercent', colspan: 2, class: 'yellow' },
        { title: 'mediumRepair', colspan: 2 },
        { title: 'mediumRepairPercent', colspan: 2 },
    ],
    18: [
        { title: 'region', colspan: 2 },
        { title: 'cnp', colspan: 1 },
        { title: 'sumSchool', colspan: 1 },
        { title: 'sumAdapted', class: 'yellow', colspan: 1 },
        { title: 'sumType', class: 'yellow', colspan: 1 },
        { title: 'sumEmergency', class: 'yellow', colspan: 1 },
        { title: 'sumSchoolZero', colspan: 1 },
        { title: 'stateStandard', class: 'yellow', colspan: 1 },
        { title: 'sum40Years', colspan: 1 },
        { title: 'sum10Years', colspan: 1 },
        { title: 'sumSchoolPercent', colspan: 1 },
        { title: 'sumTypePercent', colspan: 1 },
    ],
    19: [
        { title: 'region', colspan: 2 },
        { title: 'cnp', colspan: 1 },
        { title: 'healthFacilities', colspan: 1 },
        { title: 'sumAdapted', class: 'yellow', colspan: 1 },
        { title: 'sumType', class: 'yellow', colspan: 1 },
        { title: 'sumEmergency', class: 'yellow', colspan: 1 },
        { title: 'noHealthFacilities', colspan: 1 },
        { title: 'stateStandard', class: 'yellow', colspan: 1 },
        { title: 'medicalPersonal', class: 'yellow', colspan: 1 },
        { title: 'moreThan40Years', colspan: 1 },
        { title: 'moreThan10Years', colspan: 1 },
    ],
    20: [
        { title: 'region', colspan: 2 },
        { title: 'cnp', colspan: 1 },
        { title: 'sumCultureFacilities', class: 'yellow', colspan: 2 },
        { title: 'sumClubAndCultureHouse', class: 'yellow', colspan: 2 },
        { title: 'sumSportsFacilities', colspan: 2 },
        { title: 'sumYouthSportsSchool', colspan: 2 },
        { title: 'sumSportCenter', colspan: 2 },
        { title: 'sumTourism', class: 'yellow', colspan: 2 }
    ]
};