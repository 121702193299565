<template>
    <div class="inner-container">
        <div class="section-title program"><span>Стратегическое планирование</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>Мониторинг СНП</span></div>
        <b-tabs class="w-100" v-model="tab">
            <b-tab title="Паспорт СНП">
                <template v-if="me && me.hasOwnProperty('katoCodes')">
                    <passport-snp
                        :me="me"
                        :reportTypes="reportTypes"
                        :regions="regions"
                        :moveTabs="moveTabs"
                        :passportFilter="passportFilter"
                        @passport="updateFromPassport"
                    />
                </template>
            </b-tab>
            <b-tab title="Согласование">
                <div class="actions-tab in-tab snp-actions">
                    <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                                        <span class="left">
                                            <i class="icon icon-info"></i>
                                        </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>
                        <b-dropdown-text>
                            <div class="info-title">
                                <span>Мониторинг СНП</span>
                            </div>
                            <div class="info-text">
                                <p>Данный модуль предназначен для сбора и анализа данных по формам мониторинга СНП по
                                    приказу Первого заместителя Премьер-Министра Республики Казахстан - Министра
                                    регионального развития Республики Казахстан от 24 сентября 2013 года № 239/ОД. .</p>
                                <div class="info-subtitle">
                                    <span>Согласование</span>
                                </div>
                                <p>Раздел предназначен для наблюдения за прогрессом заполнения паспортов СНП, статусов и
                                    для согласования/утверждения форм.</p>
                            </div>
                        </b-dropdown-text>
                    </b-dropdown>
                </div>
                <template v-if="me && me.hasOwnProperty('initialSnp') && me.hasOwnProperty('katoCodes')">
                    <reconciliation
                        :reportTypes="[...reportTypes, ...registryArr]"
                        :regions="regions"
                        :me="me"
                        @go-to-passport="goToPassport"
                    />
                </template>
            </b-tab>
            <b-tab title="Формы Мониторинга">
                <div class="actions-tab in-tab snp-actions">
                    <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                                        <span class="left">
                                            <i class="icon icon-info"></i>
                                        </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>
                        <b-dropdown-text>
                            <div class="info-title">
                                <span>Мониторинг СНП</span>
                            </div>
                            <div class="info-text">
                                <p>Данный модуль предназначен для сбора и анализа данных по формам мониторинга СНП по
                                    приказу Первого заместителя Премьер-Министра Республики Казахстан - Министра
                                    регионального развития Республики Казахстан от 24 сентября 2013 года № 239/ОД. .</p>
                                <div class="info-subtitle">
                                    <span>Формы мониторинга</span>
                                </div>
                                <p>Раздел предназначен для выгрузки исторических и текущих форм мониторинга.</p>
                            </div>
                        </b-dropdown-text>
                    </b-dropdown>
                </div>
                <monitoring-form :reportTypes="reportTypes" :regions="regions"/>
            </b-tab>
            <b-tab title="Реестр">
                <template v-if="me && me.hasOwnProperty('initialSnp') && me.hasOwnProperty('katoCodes')">
                    <registry
                        :me="me"
                        :regions="regions"
                        :reportTypes="reportTypes"
                        :registryArr="registryArr"
                        :yearsArr="years"
                        :fromPassport="fromPassport"
                    />
                </template>
            </b-tab>
            <b-tab title="Конструктор">
                <constructor :me="me" :regionArr="regions" :yearsArr="years"/>
            </b-tab>
            <b-tab title="Анализ Формы">
                <form-analysis :years="years"/>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import PassportSNP from '@/modules/monitoring-snp/PassportSNP.vue';
import MonitoringForm from '@/modules/monitoring-snp/MonitoringForm.vue';
import FormAnalysis from '@/modules/monitoring-snp/FormAnalysis.vue';
import Constructor from '@/modules/monitoring-snp/Constructor.vue';
import Reconciliation from '@/modules/monitoring-snp/Reconciliation.vue';
import Registry from '@/modules/monitoring-snp/Registry.vue';
import { MonitoringSnpApi } from '@/modules/monitoring-snp/api/MonitoringSnpApi';

@Component({
    components: {
        'passport-snp': PassportSNP,
        'monitoring-form': MonitoringForm,
        'form-analysis': FormAnalysis,
        'constructor': Constructor,
        'reconciliation': Reconciliation,
        'registry': Registry
    }
})
export default class MonitoringSnp extends Vue {
    reportTypes = [];
    regions = [];
    years = [];
    me = {};
    tab = 0;
    registryArr = [];
    obl = '';
    fromPassport = '';
    passportFilter = null;

    mounted() {
        this.getMe();
        this.loadTypes();
        this.loadRegions();
        this.listOfYears();
        this.getRegistryTypes();
    }

    updateFromPassport(fromPassport) {
        this.fromPassport = fromPassport;
    }

    goToPassport(passportFilter) {
        this.tab = 0;
        this.passportFilter = passportFilter;
    }

    moveTabs(index) {
        this.tab = index;
    }

    getMe() {
        MonitoringSnpApi
            .get('/snp/api/v1/me')
            .then(res => {
                this.me = res.data;
                this.obl = this.me.katoCodes[0].substr(0, 2) + "0000000";
                MonitoringSnpApi
                    .get('snp/api/v1/reconciliations/', { katoCode: this.me.katoCodes[0], reportId: 1 })
                    .then(res => {
                        this.me.initialSnp = {
                            ...res.data[0],
                            nameRu: res.data[0].snpNameRu
                        }
                        console.log('initialSnp', this.me.initialSnp);
                    })
                    .catch(() => {
                    });
            })
            .catch(() => {
            });
    }

    loadTypes() {
        MonitoringSnpApi
            .get('/snp/api/v1/report/types')
            .then(res => this.reportTypes = res.data)
            .catch(() => {
            });
    }

    loadRegions() {
        MonitoringSnpApi
            .get('/snp/api/v1/admin/dict-kato')
            .then(res => {
              this.regions =  [{code: this.obl, nameRu: 'Все'}, ...res.data]
            })
            .catch(() => {
            });
    }

    listOfYears() {
        MonitoringSnpApi
            .get('/snp/api/v1/admin/years')
            .then(res => {
                this.years = res.data;
                //this.years.push(2022);
            })
            .catch(() => {
            });
    }

    getRegistryTypes() {
        MonitoringSnpApi.get(`/snp/api/v1/registry/types`)
            .then(res => {
                this.registryArr = res.data;
            })
            .catch(err => {
                console.log('err', err)
            });
    }
}
</script>

<style>
.info-title {
    padding-left: 1em;
    font-weight: 700;
}

.info-subtitle {
    font-weight: 600;
    margin-bottom: .8em;
}
</style>
